import {
  deleteUser,
  getInfo,
  login,
  refreshToken,
  logout,
  update,
  users,
  add,
  get,
  forgotPassword,
  resetPassword,
  changePassword,
  addDevice,
  resendEmail,
  config,
  getConfig,
  verifyPasswordToken,
} from '@/api/user';
import {
  removeToken,
  setToken,
  setTokenExpiration,
  removeTokenExpiration,
} from '@/utils/auth';
import { removeDefaultLang } from '@/utils/language';
import router, { resetRouter } from '@/router';
import { getDefaultWidgetsIds } from '@/views/home/components/widgets';
import { setLocale, getLocale } from '@/utils/locale';

const state = {
  token: window.sessionStorage.getItem('token'),
  firstName: '',
  lastName: '',
  email: '',
  roles: [],
  permissions: [],
  lang: '',
  uid: '',
  partner: {},
  widgets: [],
};

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_FIRST_NAME: (state, payload) => {
    state.firstName = payload;
  },
  SET_LAST_NAME: (state, payload) => {
    state.lastName = payload;
  },
  SET_EMAIL: (state, payload) => {
    state.email = payload;
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles;
  },
  SET_PERMISSIONS: (state, permissions) => {
    state.permissions = permissions;
  },
  SET_LANG: (state, lang) => {
    state.lang = lang;

    if (lang) {
      setLocale(lang);
    }
  },
  SET_UID: (state, payload) => {
    state.uid = payload;
  },
  SET_PARTNER: (state, payload) => {
    state.partner = payload;
  },
  SET_WIDGETS: (state, payload) => {
    state.widgets = payload;
  },
};

const actions = {
  login({ commit }, { data, headers }) {
    const { email, password } = data;
    return new Promise((resolve, reject) => {
      login({ email: email.trim(), password }, headers)
        .then((data) => {
          // const { data } = response
          commit('SET_TOKEN', data.token);
          setToken(data.token);
          setTokenExpiration(data.token_expiration);
          setLocale(data.lang);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  refreshToken({ dispatch, commit }) {
    return new Promise((resolve, reject) => {
      refreshToken().then((data) => {
        commit('SET_TOKEN', data.token);
        setToken(data.token);
        setTokenExpiration(data.token_expiration);
        resolve(data);
      }).catch((err) => {
        dispatch('resetToken');
        reject(err);
      });
    });
  },

  // get user info
  getInfo({ commit }) {
    return new Promise((resolve, reject) => {
      getInfo()
        .then((data) => {
          // const { data } = response

          if (!data) {
            reject('Verification failed, please Login again.');
          }

          const {
            firstName, lastName, email, roles, permissions, lang, uid,
          } = data;

          commit('SET_ROLES', roles);
          commit('SET_PERMISSIONS', permissions);
          commit('SET_FIRST_NAME', firstName);
          commit('SET_LAST_NAME', lastName);
          commit('SET_EMAIL', email);
          commit('SET_UID', uid);

          const language = getLocale();
          commit('SET_LANG', language || lang);

          if (data.partner) {
            commit('SET_PARTNER', data.partner);
          }

          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // user logout
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      logout({ accessToken: state.token })
        .then(() => {
          commit('SET_TOKEN', '');
          commit('SET_ROLES', []);
          commit('SET_PERMISSIONS', []);
          removeToken();
          resetRouter();
          removeTokenExpiration();
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // remove token
  resetToken({ commit }) {
    return new Promise((resolve) => {
      commit('SET_TOKEN', '');
      commit('SET_ROLES', []);
      commit('SET_PERMISSIONS', []);
      removeToken();
      removeDefaultLang();
      resolve();
    });
  },

  // forgot password
  forgotPassword(_, data) {
    return forgotPassword(data);
  },

  // reset password
  resetPassword(_, data) {
    return new Promise((resolve, reject) => {
      resetPassword(data)
        .then((resp) => {
          if (resp.ok === true) resolve();
          else reject();
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  // change password
  changePassword(_, data) {
    return new Promise((resolve, reject) => {
      changePassword(data)
        .then((resp) => {
          if (resp.ok === true) resolve();
          else reject();
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  // resend invitation email
  resendEmail(_, data) {
    return resendEmail(data);
  },

  verifyPasswordToken(_, data) {
    return verifyPasswordToken(data);
  },

  // dynamically modify permissions
  async changeRoles({ commit, dispatch }, role) {
    const token = `${role}-token`;

    commit('SET_TOKEN', token);
    setToken(token);

    const { roles } = await dispatch('getInfo');

    resetRouter();

    // generate accessible routes map based on roles
    const accessRoutes = await dispatch('permission/generateRoutes', roles, { root: true });
    // dynamically add accessible routes
    router.addRoutes(accessRoutes);
  },
  // users list
  users(_, params) {
    return users(params);
  },
  // update user
  update(_, payload) {
    return update(payload);
  },
  // delete user
  delete(_, payload) {
    return deleteUser(payload);
  },
  // add user
  add(_, payload) {
    return add(payload);
  },
  // get user by uid
  get(_, payload) {
    return get(payload);
  },
  addDevice(_, payload) {
    return addDevice(payload);
  },
  async updateWidgets({ commit }, payload) {
    const res = await config({ widgets: payload });
    commit('SET_WIDGETS', payload);
    return res;
  },
  async getWidgets({ commit, state }) {
    const metaData = await getConfig();
    if (metaData && metaData.widgets) {
      commit('SET_WIDGETS', metaData.widgets);
    } else {
      commit('SET_WIDGETS', getDefaultWidgetsIds(state.permissions, state.roles));
    }
    return metaData;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
