import {
  destinations,
  properties,
  extractionsExport,
  getProperty,
  getReservation,
  getRoomPrices,
} from '@/api/simulations/extractions';

const state = {
};

const mutations = {
};

const actions = {
  async list(_, payload) {
    return properties(payload);
  },
  async destinations(_, payload) {
    return destinations(payload);
  },
  extractionsExport(_, payload) {
    return extractionsExport(payload);
  },
  getProperty(_, id) {
    return getProperty(id);
  },
  getReservation(_, payload) {
    const { id, ...params } = payload;
    return getReservation(id, params);
  },
  getRoomPrices(_, payload) {
    const { id, ...params } = payload;
    return getRoomPrices(id, params);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
