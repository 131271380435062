import { ROLES } from '@/config/roles';

export default [
  {
    path: '/iscm/auto-reflection-settings',
    name: 'iscm-auto-reflection-settings',
    component: () => import('@/views/iscm/price-auto-reflection/List'),
    meta: {
      roles: [ROLES.ADMIN],
    },
  },
  {
    path: '/iscm/rates/per-room',
    name: 'iscm-rates-per-room',
    component: () => import('@/views/iscm/rates/PerRoom'),
    meta: {
      roles: [ROLES.ADMIN],
    },
  },
  {
    path: '/iscm/rates/per-person',
    name: 'iscm-rates-per-person',
    component: () => import('@/views/iscm/rates/PerPerson'),
    meta: {
      roles: [ROLES.ADMIN],
    },
  },
];
