/**
 * this module will be automatically converted as "rms-settings"
 */
import {
  list,
  get,
  add,
  update,
  deletePlan,
} from '@/api/rms/rate-plans';

const state = {
  loading: false,
  rms: undefined,
  competitor: undefined,
};

const mutations = {
  SET_LOADING: (state, payload) => {
    state.loading = payload;
  },
  SET_RATE_PLAN: (state, payload) => {
    state.rms = payload;
  },
};

const actions = {
  list(_, params) {
    return list(params);
  },
  async get({ commit }, params) {
    commit('SET_LOADING', true);
    commit('SET_RATE_PLAN', undefined);
    commit('SET_COMPETITOR', undefined);

    try {
      const result = await get(params);

      commit('SET_RATE_PLAN', result.data);

      return result;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  add(_, payload) {
    return add(payload);
  },
  update(_, payload) {
    return update(payload);
  },
  deletePlan(_, params) {
    return deletePlan(params);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
