import {
  list, names, add, deleteUser, get, update,
} from '@/api/partners';

const state = {
  loading: false,
  partner: undefined,
};

const mutations = {
  SET_LOADING: (state, payload) => {
    state.loading = payload;
  },
  SET_PARTNER: (state, payload) => {
    state.partner = payload;
  },
};

const actions = {
  list(_, payload) {
    return list(payload);
  },
  names(_, payload) {
    return names(payload);
  },
  update(_, payload) {
    return update(payload);
  },
  delete(_, payload) {
    return deleteUser(payload);
  },
  add(_, payload) {
    return add(payload);
  },
  async get({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      commit('SET_PARTNER', undefined);
      const res = await get(payload);
      commit('SET_PARTNER', res);
      commit('SET_LOADING', false);
      return res;
    } catch (error) {
      commit('SET_LOADING', false);
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
