import {
  saveRatePerRoomData,
  saveRatePerPersonData,
  getRatePerPersonData,
  getRatePerRoomData,
} from '../../../api/iscm/savedData';

const state = {
  loading: false,
  rate: undefined,
};

const mutations = {
  SET_LOADING: (state, payload) => {
    state.loading = payload;
  },

  SET_RATES: (state, payload) => {
    state.rate = payload;
  },

  GET_RATES: (state) => state.rate,
};

const actions = {
  async ratePerRoom({ commit }, params) {
    commit('SET_LOADING', true);
    commit('SET_RATES', undefined);

    try {
      const data = await getRatePerRoomData(params);
      commit('SET_RATES', data);
      return data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async ratePerPerson({ commit }, params) {
    commit('SET_LOADING', true);
    commit('SET_RATES', undefined);

    try {
      const data = await getRatePerPersonData(params);
      commit('SET_RATES', data);
      return data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async saveRatePerRoom({ commit }, data) {
    commit('SET_LOADING', true);
    try {
      const result = await saveRatePerRoomData(data);
      return result;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async saveRatePerPerson({ commit }, data) {
    commit('SET_LOADING', true);
    try {
      const result = await saveRatePerPersonData(data);
      return result;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
