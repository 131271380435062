import store from '@/store';

/**
 * @example see @/views/role/directive.vue
 * @param {Array} value
 * @returns {Boolean}
 */
export default function checkRole(value) {
  if (value && value instanceof Array && value.length > 0) {
    const roles = store.getters && store.getters.roles;
    const roleValues = value;

    const hasRole = roles.some((role) => roleValues.includes(role));

    return hasRole;
  }

  return false;
}
