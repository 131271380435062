import { list, roomList } from '@/api/iscm/property';

const state = {
  loading: false,
  properties: undefined,
  rooms: undefined,
};

const mutations = {
  SET_LOADING: (state, payload) => {
    state.loading = payload;
  },

  SET_PROPERTIES: (state, payload) => {
    state.properties = payload;
  },

  GET_PROPERTIES: (state) => state.properties,

  SET_ROOMS: (state, payload) => {
    state.rooms = payload;
  },

  GET_ROOMS: (state) => state.rooms,
};

const actions = {
  async list({ commit }, { vendor }) {
    commit('SET_LOADING', true);
    commit('SET_PROPERTIES', undefined);

    try {
      const data = await list(vendor);
      commit('SET_PROPERTIES', data);
      return data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async listRoom({ commit }, { hotelNo, type, vendor }) {
    commit('SET_LOADING', true);
    commit('SET_ROOMS', undefined);

    try {
      const data = await roomList(hotelNo, type, vendor);
      commit('SET_ROOMS', data);
      return data;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
