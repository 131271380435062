import {
  list,
  add,
  del,
  exportSimulation,
  get,
  occBefore,
  latestVersion,
  getVersion,
  update,
  getCalculationAdjustment,
  saveCalculationAdjustment,
  listRoomAddons,
  addRoomAddon,
  updateRoomAddon,
  deleteRoomAddon,
  getRoomTypes,
  deleteRoomTypes,
  getGeneralAddonLists,
  addGeneralAddon,
  updateGeneralAddon,
  deleteGeneralAddon,
  addPrefecture,
  updatePrefecture,
  getPrefectureLists,
  prefectureOccAverages,
  deletePrefecture,
  getRoomSize,
  addRoomSize,
  updateRoomSize,
  deleteRoomSize,
  getReservationMeal,
  saveReservationMeal,
  deleteReservationMeal,
} from '@/api/simulations/simulations';

const state = {
  loading: false,
  simulation: undefined,
};

const mutations = {
  SET_LOADING: (state, payload) => {
    state.loading = payload;
  },
  SET_SIMULATION: (state, payload) => {
    state.simulation = payload;
  },
};

const actions = {
  list(_, payload) {
    return list(payload);
  },
  occBefore(_, payload) {
    return occBefore(payload);
  },
  update(_, payload) {
    return update(payload);
  },
  delete(_, id) {
    return del(id);
  },
  add(_, payload) {
    return add(payload);
  },
  exportSimulation(_, payload) {
    return exportSimulation(payload);
  },
  latestVersion(_, payload) {
    return latestVersion(payload);
  },
  getVersion(_, payload) {
    return getVersion(payload);
  },
  async get({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      commit('SET_SIMULATION', undefined);
      const res = await get(payload);
      commit('SET_SIMULATION', res);
      commit('SET_LOADING', false);

      return res;
    } catch (error) {
      commit('SET_LOADING', false);
      throw error;
    }
  },
  getRoomTypes(_, payload) {
    return getRoomTypes(payload);
  },
  deleteRoomTypes(_, payload) {
    return deleteRoomTypes(payload);
  },
  getCalculationAdjustment(_, payload) {
    return getCalculationAdjustment(payload);
  },
  saveCalculationAdjustment(_, payload) {
    return saveCalculationAdjustment(payload);
  },
  getGeneralAddonLists(_, payload) {
    return getGeneralAddonLists(payload);
  },
  addGeneralAddon(_, payload) {
    return addGeneralAddon(payload);
  },
  updateGeneralAddon(_, payload) {
    return updateGeneralAddon(payload);
  },
  deleteGeneralAddon(_, payload) {
    return deleteGeneralAddon(payload);
  },
  getPrefectureLists(_, payload) {
    return getPrefectureLists(payload);
  },
  prefectureOccAverages(_, payload) {
    return prefectureOccAverages(payload);
  },
  addPrefecture(_, payload) {
    return addPrefecture(payload);
  },
  updatePrefecture(_, payload) {
    return updatePrefecture(payload);
  },
  deletePrefecture(_, payload) {
    return deletePrefecture(payload);
  },
  listRoomAddons(_, payload) {
    return listRoomAddons(payload);
  },
  addRoomAddon(_, payload) {
    return addRoomAddon(payload);
  },
  updateRoomAddon(_, payload) {
    return updateRoomAddon(payload);
  },
  deleteRoomAddon(_, payload) {
    return deleteRoomAddon(payload);
  },
  getRoomSize(_, payload) {
    return getRoomSize(payload);
  },
  addRoomSize(_, payload) {
    return addRoomSize(payload);
  },
  updateRoomSize(_, payload) {
    return updateRoomSize(payload);
  },
  deleteRoomSize(_, payload) {
    return deleteRoomSize(payload);
  },
  getReservationMeal(_, payload) {
    return getReservationMeal(payload);
  },
  saveReservationMeal(_, payload) {
    return saveReservationMeal(payload);
  },
  deleteReservationMeal(_, reservationNumber) {
    return deleteReservationMeal(reservationNumber);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
