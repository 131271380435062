import {
  small,
  detail,
} from '@/api/simulations/simulation-areas';

const actions = {
  small(_, payload) {
    return small(payload);
  },
  detail(_, payload) {
    return detail(payload);
  },
};

export default {
  namespaced: true,
  actions,
};
