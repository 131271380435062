import { ratePerRoom, ratePerPerson } from '@/api/iscm/piop';

const state = {
  loading: false,
  rates: undefined,
};

const mutations = {
  SET_LOADING: (state, payload) => {
    state.loading = payload;
  },

  SET_RATE: (state, payload) => {
    state.rates = payload;
  },

  GET_RATES: (state) => state.rates,
};

const actions = {
  async ratePerRoom({ commit }, params) {
    commit('SET_LOADING', true);
    commit('SET_RATE', undefined);

    try {
      const data = await ratePerRoom(params);
      commit('SET_RATE', data);
      return data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async ratePerPerson({ commit }, params) {
    commit('SET_LOADING', true);
    commit('SET_RATE', undefined);

    try {
      const data = await ratePerPerson(params);
      commit('SET_RATE', data);
      return data;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
