/**
 * this module will be automatically converted as "rms-rates"
 */
import {
  list,
  saveByProperty,
  neppan,
  submitToNeppan,
  exportRates,
  neppanPlans,
} from '@/api/rms/rates';

const state = {
  loading: false,
};

const mutations = {
  SET_LOADING: (state, payload) => {
    state.loading = payload;
  },
};

const actions = {
  list(_, params) {
    return list(params);
  },
  saveByProperty(_, payload) {
    return saveByProperty(payload);
  },
  neppan(_, params) {
    return neppan(params);
  },
  neppanPlans(_, params) {
    return neppanPlans(params);
  },
  submitToNeppan(_, payload) {
    return submitToNeppan(payload);
  },
  exportRates(_, payload) {
    return exportRates(payload);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
