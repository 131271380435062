import Message from 'ant-design-vue/lib/message';
import NProgress from 'nprogress'; // progress bar
import router from './router';
import store from './store';
import 'nprogress/nprogress.css'; // progress bar style
import {
  getToken, getTokenExpiration, logout,
} from '@/utils/auth';
import getPageTitle from '@/utils/get-page-title';

const whiteList = ['/login', '/forgot-password', '/reset-password', '/set-initial-password']; // no redirect whitelist
NProgress.configure({ showSpinner: false });

const checkPermission = async (roles, route, next) => {
  await store.dispatch(
    'permission/checkRoute', {
      roles,
      route,
    },
  ).catch(() => next({ path: '/' }));
};

router.beforeEach(async (to, from, next) => {
  // start progress bar
  NProgress.start();
  // set page title
  document.title = getPageTitle(to.meta.title);

  // determine whether the user has logged in
  let hasToken = getToken();
  const tokenExpiration = getTokenExpiration() || 0;

  if (tokenExpiration < new Date().getTime()) {
    await store.dispatch('user/refreshToken')
      .then(() => {
        hasToken = getToken();
      })
      .catch(() => {
        if (whiteList.includes(to.path)) {
          return next();
        }

        return logout(to.path);
      });
  }

  if (hasToken) {
    if (to.path === '/login' || to.path === '/set-initial-password') {
      // if is logged in, redirect to the home page
      next({ path: '/' });
      NProgress.done();
    } else {
      // determine whether the user has obtained his permission roles through getInfo
      const hasRoles = store.getters.roles && store.getters.roles.length > 0;
      if (hasRoles) {
        await checkPermission(
          store.state.user.roles,
          to,
          next,
        );
        next();
      } else {
        try {
          // get user info
          // note: roles must be a object array! such as: ['admin'] or ,['developer','editor']
          await store.dispatch('user/getInfo');
          // generate accessible routes map based on roles
          await checkPermission(
            store.state.user.roles,
            to,
            next,
          );
          // const accessRoutes = await store.dispatch(
          //   'permission/generateRoutes',
          //   store.state.user.roles,
          // );

          // dynamically add accessible routes
          // accessRoutes.forEach((r) => {
          //   router.addRoute(r);
          // });

          // hack method to ensure that addRoutes is complete
          // set the replace: true, so the navigation will not leave a history record
          next({ ...to, replace: true });
        } catch (error) {
          // remove token and go to login page to re-login
          console.log(error);
          await store.dispatch('user/resetToken');
          Message.error(error.message || 'Has Error');
          next(`/login?redirect=${to.path}`);
          NProgress.done();
        }
      }
    }
  } else {
    /* has no token */

    /* eslint-disable */
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next();
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}`);
      store.commit('HIDE_FULLSCREEN_LOADER');
    }
  }
});

router.afterEach(() => {
  // finish progress bar
  NProgress.done();
});
