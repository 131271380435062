const key = 'QUERY_STRING_VALUE';

/**
 * Save route query to session
 * @param {Object} query
 * @param {String} name
 */
export function saveRouteHistory(query, name) {
  window.sessionStorage.setItem(key, JSON.stringify({
    query, name,
  }));
}

/**
 * Get route query from session
 * @returns {Object|Boolean}
 */
export function getRouteHistory() {
  const item = window.sessionStorage.getItem(key);
  if (item) {
    return JSON.parse(item);
  }

  return undefined;
}
