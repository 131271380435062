import {
  list, add, edit, deletes,
} from '@/api/rms/fractions';

const state = {
  fractions: undefined,
  loading: undefined,
};

const mutations = {
  SET_FRACTION: (state, payload) => {
    state.fractions = payload;
  },
  SET_LOADING: (state, payload) => {
    state.loading = payload;
  },
};

const actions = {
  list(_, params) {
    const withSort = { ...params, sortField: 'condition', sortOrder: 'asc' };

    return list(withSort);
  },
  add(_, data) {
    return add(data);
  },
  edit(_, data) {
    return edit(data);
  },
  delete(_, data) {
    return deletes(data);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
