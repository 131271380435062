import {
  list,
  add,
  del,
  get,
  update,
  uploadReceipt,
  approve,
  requestApproval,
} from '@/api/expenses';

const state = {
  loading: false,
  expense: undefined,
};

const mutations = {
  SET_LOADING: (state, payload) => {
    state.loading = payload;
  },
  SET_EXPENSE: (state, payload) => {
    state.expense = payload;
  },
};

const actions = {
  list(_, payload) {
    return list(payload);
  },
  update(_, payload) {
    return update(payload);
  },
  delete(_, payload) {
    return del(payload);
  },
  add(_, payload) {
    return add(payload);
  },
  uploadReceipt(_, payload) {
    return uploadReceipt(payload);
  },
  approve(_, payload) {
    return approve(payload);
  },
  requestApproval(_, payload) {
    return requestApproval(payload);
  },
  async get({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      commit('SET_EXPENSE', undefined);
      const res = await get(payload);
      commit('SET_EXPENSE', res);
      commit('SET_LOADING', false);
      return res;
    } catch (error) {
      commit('SET_LOADING', false);
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
