import Button from 'ant-design-vue/lib/button';
import Alert from 'ant-design-vue/lib/alert';
import Rate from 'ant-design-vue/lib/rate';
import Dropdown from 'ant-design-vue/lib/dropdown';
import Form from 'ant-design-vue/lib/form';
import Input from 'ant-design-vue/lib/input';
import InputNumber from 'ant-design-vue/lib/input-number';
import Message from 'ant-design-vue/lib/message';
import Modal from 'ant-design-vue/lib/modal';
import Pagination from 'ant-design-vue/lib/pagination';
import Radio from 'ant-design-vue/lib/radio';
import Spin from 'ant-design-vue/lib/spin';
import Switch from 'ant-design-vue/lib/switch';
import Table from 'ant-design-vue/lib/table';
import Checkbox from 'ant-design-vue/lib/checkbox';
import Select from 'ant-design-vue/lib/select';
import AutoComplete from 'ant-design-vue/lib/auto-complete';
import Empty from 'ant-design-vue/lib/empty';
import DatePicker from 'ant-design-vue/lib/date-picker';
import TimePicker from 'ant-design-vue/lib/time-picker';
import Popover from 'ant-design-vue/lib/popover';
import Upload from 'ant-design-vue/lib/upload';
import Drawer from 'ant-design-vue/lib/drawer';
import ConfigProvider from 'ant-design-vue/lib/config-provider';
import Calendar from 'ant-design-vue/lib/calendar';
import Tag from 'ant-design-vue/lib/tag';
import Menu from 'ant-design-vue/lib/menu';
import Slider from 'ant-design-vue/lib/slider';
import Collapse from 'ant-design-vue/lib/collapse';
import Mention from 'ant-design-vue/lib/mentions';
import Tooltip from 'ant-design-vue/lib/tooltip';

export default {
  // eslint-disable-next-line no-unused-vars
  install: (app, options) => {
    app.use(Button);
    app.use(Alert);
    app.use(Form);
    app.use(Input);
    app.use(InputNumber);
    app.config.globalProperties.$message = Message;
    app.config.globalProperties.$confirm = Modal.confirm;
    // app.use(Message)
    app.use(Modal);
    app.use(Pagination);
    app.use(Radio);
    app.use(Spin);
    app.use(Switch);
    app.use(Table);
    app.use(Checkbox);
    app.use(Select);
    app.use(AutoComplete);
    app.use(Rate);
    app.use(Empty);
    app.use(DatePicker);
    app.use(Popover);
    app.use(Upload);
    app.use(Drawer);
    app.use(ConfigProvider);
    app.use(TimePicker);
    app.use(Calendar);
    app.use(Tag);
    app.use(Dropdown);
    app.use(Menu);
    app.use(Slider);
    app.use(Collapse);
    app.use(Mention);
    app.use(Tooltip);
  },
};
