import countriesStates from './countriesStates';
import japanPrefectures from './japanPrefectures';
import { getLocale } from '@/utils/locale';
import { getDefaultLang } from '@/utils/language';

export const getCountries = () => countriesStates.map((c) => ({ iso2: c.iso2, name: c.name }));

export const getJapanOnly = () => countriesStates
  .filter((country) => country.iso2 === 'JP')
  .map((c) => ({ iso2: c.iso2, name: c.name }));

export const getStatesByCountry = (iso2, valueLocale = null) => {
  const locale = getLocale() || getDefaultLang();
  if (!iso2) return [];
  if (iso2.toLowerCase() === 'jp') {
    const japanStates = japanPrefectures.map((prefecture) => ({
      value: prefecture[valueLocale] || prefecture[locale] || prefecture.en,
      name: prefecture[locale] || prefecture.en,
    }));
    return japanStates.sort((a, b) => a.name.localeCompare(b.name));
  }
  const country = countriesStates.find((c) => c.iso2 === iso2);

  if (country && country.states) {
    return country.states.map((state) => ({
      ...state,
      value: state.name,
    }));
  }

  return [];
};

export const getCountryByIso = (iso2) => {
  const country = countriesStates.find((c) => c.iso2 === iso2);
  return country ? country.name : '';
};

export const getCountriesPh = () => countriesStates.map((c) => ({
  phoneCode: `+${c.phone_code.replace('+', '')}`,
  name: c.name,
}));

export const getAreaPrefecture = (prefecture) => {
  for (let i = 0; i < japanPrefectures.length; i += 1) {
    const { ja, en } = japanPrefectures[i];

    if (ja === prefecture || en === prefecture) {
      return ja; // we only need the japanese for area
    }
  }

  return '';
};
