import {
  add, del, list, update,
} from '@/api/room-types';

const actions = {
  add(_, payload) {
    return add(payload);
  },
  list(_, params) {
    return list(params);
  },
  update(_, payload) {
    return update(payload);
  },
  del(_, id) {
    return del(id);
  },
};

export default {
  namespaced: true,
  actions,
};
