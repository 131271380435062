/**
 * this module will be automatically converted as "rms-competitor-hotels"
 */
import {
  list,
} from '@/api/rms/competitor-hotels';

const actions = {
  list(_, params) {
    return list(params);
  },
};

export default {
  namespaced: true,
  actions,
};
