import {
  list,
  add,
  del,
  get,
  update,
  getChecklist,
  finish,
  taskByAssignment,
  checkChecklist,
  uncheckChecklist,
  remainingTask,
  start,
} from '@/api/housekeeper-tasks';

const state = {
  loading: false,
  task: undefined,
};

const mutations = {
  SET_LOADING: (state, payload) => {
    state.loading = payload;
  },
  SET_TASK: (state, payload) => {
    state.task = payload;
  },
};

const actions = {
  list(_, payload) {
    return list(payload);
  },
  update(_, payload) {
    return update(payload);
  },
  delete(_, payload) {
    return del(payload);
  },
  add(_, payload) {
    return add(payload);
  },
  async get({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      commit('SET_TASK', undefined);
      const res = await get(payload);
      commit('SET_TASK', res);
      commit('SET_LOADING', false);
      return res;
    } catch (error) {
      commit('SET_LOADING', false);
      throw error;
    }
  },
  getChecklist(_, payload) {
    return getChecklist(payload);
  },
  finish(_, payload) {
    return finish(payload);
  },
  checkedChecklist(_, payload) {
    return checkChecklist(payload);
  },
  uncheckedChecklist(_, payload) {
    return uncheckChecklist(payload);
  },
  getTaskByAssignment(_, payload) {
    return taskByAssignment(payload);
  },
  getRemainingTask(_, payload) {
    return remainingTask(payload);
  },
  start(_, payload) {
    return start(payload);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
