/**
 * this module will be automatically converted as "rms-locked-dates"
 */
import {
  list,
  get,
  create,
  update,
  deleteRecord,
} from '@/api/rms/locked-dates';

const state = {
  loading: false,
  lockedDate: undefined,
};

const mutations = {
  SET_LOADING: (state, payload) => {
    state.loading = payload;
  },
  SET_LOCKED_DATE: (state, payload) => {
    state.lockedDate = payload;
  },
};

const actions = {
  list(_, params) {
    return list(params);
  },
  async get({ commit }, params) {
    commit('SET_LOADING', true);
    commit('SET_LOCKED_DATE', undefined);

    try {
      const result = await get(params);

      commit('SET_LOCKED_DATE', result.data);

      return result;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  create(_, payload) {
    return create(payload);
  },
  update(_, payload) {
    return update(payload);
  },
  deleteRecord(_, params) {
    return deleteRecord(params);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
