import {
  addBlockedDates, editBlockedDates, deleteBlockedDates, list, deleteBlockedDateMany,
  addBlockedDatesToRooms,
  deleteBlockedDateManyRooms,
} from '@/api/calendar';

const state = {
  loading: false,
  expense: undefined,
  showName: true,
};

const mutations = {
  SET_LOADING: (state, payload) => {
    state.loading = payload;
  },
  SET_EXPENSE: (state, payload) => {
    state.expense = payload;
  },
  SHOW_NAME: (state) => {
    state.showName = true;
  },
  HIDE_NAME: (state) => {
    state.showName = false;
  },
};

const actions = {
  list(_, params) {
    return list(params);
  },
  addBlockedDates(_, params) {
    return addBlockedDates(params);
  },
  editBlockedDates(_, params) {
    return editBlockedDates(params);
  },
  deleteBlockedDates(_, params) {
    return deleteBlockedDates(params);
  },
  deleteBlockedDateMany(_, params) {
    return deleteBlockedDateMany(params);
  },
  changeShowName({ commit }, { showName }) {
    if (showName) {
      commit('SHOW_NAME');
    } else {
      commit('HIDE_NAME');
    }
  },
  addBlockedDatesToRooms(_, params) {
    return addBlockedDatesToRooms(params);
  },
  deleteBlockedDateManyRooms(_, params) {
    return deleteBlockedDateManyRooms(params);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
