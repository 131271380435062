import {
  list,
  names,
  add,
  del,
  get,
  inspectionList,
  stocks,
  addInspectionList,
  delInspectionRoom,
  update,
  uploadDiagram,
  listAnshinAccommodations,
  listNeppanRoomTypes,
} from '@/api/rooms';

const state = {
  loading: false,
  room: undefined,
};

const mutations = {
  SET_LOADING: (state, payload) => {
    state.loading = payload;
  },
  SET_ROOM: (state, payload) => {
    state.room = payload;
  },
};

const actions = {
  list(_, payload) {
    return list(payload);
  },
  names(_, payload) {
    return names(payload);
  },
  inspectionList(_, payload) {
    return inspectionList(payload);
  },
  stocks(_, payload) {
    return stocks(payload);
  },
  update(_, payload) {
    return update(payload);
  },
  uploadDiagram(_, payload) {
    return uploadDiagram(payload);
  },
  delete(_, payload) {
    return del(payload);
  },
  add(_, payload) {
    return add(payload);
  },
  addInspectionList(_, payload) {
    return addInspectionList(payload);
  },
  delInspectionRoom(_, payload) {
    return delInspectionRoom(payload);
  },
  async get({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      commit('SET_ROOM', undefined);
      const res = await get(payload);
      commit('SET_ROOM', res);
      commit('SET_LOADING', false);
      return res;
    } catch (error) {
      commit('SET_LOADING', false);
      throw error;
    }
  },
  listAnshinAccommodations(_, params) {
    return listAnshinAccommodations(params);
  },
  listNeppanRoomTypes(_, params) {
    return listNeppanRoomTypes(params);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
