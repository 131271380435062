import {
  list,
  add,
  get,
  update,
  del,
  active,
  inactive,
} from '@/api/housekeeping-cycles';

const state = {
  loading: false,
  cycle: undefined,
};

const mutations = {
  SET_LOADING: (state, payload) => {
    state.loading = payload;
  },
  SET_CYCLE: (state, payload) => {
    state.cycle = payload;
  },
};

const actions = {
  list(_, payload) {
    return list(payload);
  },
  add(_, payload) {
    return add(payload);
  },
  update(_, payload) {
    return update(payload);
  },
  delete(_, payload) {
    return del(payload);
  },
  async get({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      commit('SET_CYCLE', undefined);
      const res = await get(payload);
      commit('SET_CYCLE', res);
      commit('SET_LOADING', false);
      return res;
    } catch (error) {
      commit('SET_LOADING', false);
      throw error;
    }
  },
  setActive(_, payload) {
    const { id, active: isActive } = payload;

    if (isActive === true) {
      return active(id);
    }

    return inactive(id);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
