import {
  list, add, del, get, update,
} from '@/api/room-assignments';

const state = {
  loading: false,
};

const mutations = {
  SET_LOADING: (state, payload) => {
    state.loading = payload;
  },
  SET_BANK: (state, payload) => {
    state.bank = payload;
  },
};

const actions = {
  list(_, payload) {
    return list(payload);
  },
  update(_, payload) {
    return update(payload);
  },
  delete(_, payload) {
    return del(payload);
  },
  add(_, payload) {
    return add(payload);
  },
  get(_, payload) {
    return get(payload);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
