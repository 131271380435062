import { saveRouteHistory, getRouteHistory } from '@/utils/routers';

const state = {
  firstLevelRoute: getRouteHistory(),
};

const mutations = {
  SET_FIRST_QUERY: (state, payload) => {
    state.firstLevelRoute = payload;
  },
};

const actions = {
  addFirstLevelQuery({ commit }, { query, name }) {
    commit('SET_FIRST_QUERY', { query, name });
    saveRouteHistory(query, name);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
