import mitt from 'mitt';
import date from '@/filters/date';
import currency from '@/filters/currency';
import currencyObj from '@/filters/currencyObj';
import cell from '@/filters/cell';
import capitalizeFirst from '@/filters/capitalizeFirst';
import roles from '@/filters/roles';
import number from '@/filters/number';
import countryNameByIso from '@/filters/countryNameByIso';
import imageUrl from '@/filters/imageUrl';
import truncate from '@/filters/truncate';
import IconBase from '@/components/IconBase';
import store from '@/store';
import permission from '@/directive/permission';
import draggable from '@/directive/draggable';
import role from '@/directive/role';
import { getDateFormat, getDateMonthFormat, getDatetimeFormat } from '@/utils/dates';

const emitter = mitt();

export default {
  // eslint-disable-next-line no-unused-vars
  install: (app, options) => {
    app.config.globalProperties.$filters = {
      date,
      currency,
      currencyObj,
      cell,
      roles,
      number,
      capitalizeFirst,
      countryNameByIso,
      imageUrl,
      truncate,
    };

    app.config.globalProperties.$variables = {
      dateFormat: getDateFormat(),
      dateMonthFormat: getDateMonthFormat(),
      datetimeFormat: getDatetimeFormat(),
      genericDateFormat: 'YYYY-MM-DD',
      genericDateTimeFormat: 'YYYY-MM-DD HH:mm',
    };

    app.config.globalProperties.$emitter = emitter;

    app.config.globalProperties.$hasPermission = (permissionValues) => {
      const permissions = store.getters && store.getters.permissions;
      const permitted = permissions.some((permission) => permissionValues.includes(permission));
      return permitted;
    };

    app.directive('permission', permission);
    app.directive('role', role);
    app.directive('draggable', draggable);
    app.component('IconBase', IconBase);
  },
};
