// The plugin vue-lazyload does not support vue3, use our modified version first
import Lazy from 'vue-lazyload/src/lazy';
import { nextTick } from 'vue';

export default {
  install: (app, options) => {
    const Vue = Object.create(app);
    Vue.nextTick = nextTick;
    const LazyClass = Lazy(Vue);
    const lazy = new LazyClass(options);
    app.directive('lazy', {
      beforeMount(el, binding, vnode) {
        lazy.add.call(lazy, el, binding, vnode);
      },
      beforeUpdate(el, binding, vnode) {
        lazy.update.call(lazy, el, binding, vnode);
      },
      updated() {
        lazy.lazyLoadHandler.call(lazy);
      },
      unmounted(el) {
        lazy.remove.call(lazy, el);
      },
    });
  },
};
