import { list, approve, reject } from '@/api/assignment-request';

const state = {};

const mutations = {};

const actions = {
  list(_, payload) {
    return list(payload);
  },
  approve(_, payload) {
    return approve(payload);
  },
  reject(_, payload) {
    return reject(payload);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
