import {
  ratePerRoom,
  ratePerPerson,
  updateRatePerPerson,
  updateRatePerRoom,
} from '@/api/iscm/external';

const state = {
  loading: false,
  rate: undefined,
};

const mutations = {
  SET_LOADING: (state, payload) => {
    state.loading = payload;
  },

  SET_RATES: (state, payload) => {
    state.rate = payload;
  },

  GET_RATES: (state) => state.rate,
};

const actions = {
  async ratePerRoom({ commit }, params) {
    commit('SET_LOADING', true);
    commit('SET_RATES', undefined);

    try {
      const data = await ratePerRoom(params);
      commit('SET_RATES', data);
      return data;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async ratePerPerson({ commit }, params) {
    commit('SET_LOADING', true);
    commit('SET_RATES', undefined);

    try {
      const data = await ratePerPerson(params);
      commit('SET_RATES', data);
      return data;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async updateRatePerRoom({ commit }, params) {
    commit('SET_LOADING', true);

    try {
      const data = await updateRatePerRoom(params);
      return data;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async updateRatePerPerson({ commit }, params) {
    commit('SET_LOADING', true);

    try {
      const data = await updateRatePerPerson(params);
      return data;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
