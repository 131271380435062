import WebFont from 'webfontloader';
import '@/assets/css/rtx-fonts.scss';

/**
 * https://www.npmjs.com/package/webfontloader
 * Use to load webfont without blocking the rendering. however it will
 * have Flash of Unstyled Text (FOUT).
 *
 * The FOUT can be more easily avoided when loading the Web Font Loader
 * synchronously, as it will automatically set the wf-loading class on the
 * HTML element as soon as Webfont.load has been called. The browser will
 * wait for the script to load before continuing to load the rest of the content,
 * FOUT is avoided.
 */
WebFont.load({
  custom: {
    families: [
      // Define the font famaily name
      // the font famaily name must same as rtx-fonts.scss
      'Rakuten Sans UI:n4,n6,n7',
      'Noto Sans JP:n3,n4,n6,n7',
    ],
  },
});
