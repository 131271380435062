import { reserveFormat } from './util';

export const getFullname = function ({ fname, lname }) {
  if (fname || lname) {
    const firstName = fname || '';
    const lastName = lname || '';
    return reserveFormat() ? `${lastName} ${firstName}` : `${firstName} ${lastName}`;
  }

  return '-';
};
