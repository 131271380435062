import {
  createTemplate,
  updateTemplate,
  listTemplate,
  showTemplate,
  deleteTemplate,
  getSender,
  updateSender,
  listEventLog,
  showEventLog,
  sendEmail,
  latestEventLog,
} from '@/api/guest-email';

const state = {
  loading: false,
};

const mutations = {};

const actions = {
  createTemplate(_, payload) {
    return createTemplate(payload);
  },
  updateTemplate(_, payload) {
    return updateTemplate(payload);
  },
  listTemplate(_, payload) {
    return listTemplate(payload);
  },
  showTemplate(_, payload) {
    return showTemplate(payload);
  },
  deleteTemplate(_, payload) {
    return deleteTemplate(payload);
  },
  /**
   * Email Sender
   */
  getSender(_, payload) {
    return getSender(payload);
  },
  updateSender(_, payload) {
    return updateSender(payload);
  },
  /**
   * Event logs
   */
  listEventLog(_, payload) {
    return listEventLog(payload);
  },
  showEventLog(_, payload) {
    return showEventLog(payload);
  },
  sendEmail(_, payload) {
    return sendEmail(payload);
  },
  latestEventLog(_, payload) {
    return latestEventLog(payload);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
