import {
  list,
  add,
  get,
  update,
  addNeppan,
  del,
  cancelNeppan,
  getNeppan,
  summary,
  monthly,
  countGroupByOta,
  csv,
  addAnshin,
  calendar,
} from '@/api/reservations';

import { cancelOrders } from '@/api/oms/upsell-item-orders';

const state = {
  loading: false,
  reservation: undefined,
};

const mutations = {
  SET_LOADING: (state, payload) => {
    state.loading = payload;
  },
  SET_RESERVATION: (state, payload) => {
    state.reservation = payload;
  },
};

const actions = {
  list(_, params) {
    return list(params);
  },
  add(_, payload) {
    return add(payload);
  },
  async get({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      commit('SET_RESERVATION', undefined);
      const res = await get(payload);
      commit('SET_RESERVATION', res);
      commit('SET_LOADING', false);
      return res;
    } catch (error) {
      commit('SET_LOADING', false);
      throw error;
    }
  },
  update(_, payload) {
    return update(payload);
  },
  async delete(_, payload) {
    const deleted = await del(payload);
    await cancelOrders({ reservationId: payload.id });

    return deleted;
  },
  addNeppan(_, payload) {
    return addNeppan(payload);
  },
  cancelNeppan(_, payload) {
    return cancelNeppan(payload);
  },
  getNeppan(_, payload) {
    return getNeppan(payload);
  },
  summary(_, params) {
    return summary(params);
  },
  monthly(_, params) {
    return monthly(params);
  },
  countGroupByOta(_, params) {
    return countGroupByOta(params);
  },
  csv(_, params) {
    return csv(params);
  },
  addAnshin(_, payload) {
    return addAnshin(payload);
  },
  calendar(_, payload) {
    return calendar(payload);
  },
  // cancel orders
  cancelOrders(_, { reservationId }) {
    return cancelOrders({ reservationId });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
