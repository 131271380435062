import dayjs from 'dayjs';
import { h } from 'vue';
import i18n from '@/i18n';
import date from '@/filters/date';
import AlertTitle from '@/views/home/components/AlertTitle';
import { getDateFormat, getDatetimeFormat } from '@/utils/dates';
import { getFullname } from '@/utils/users';

const NOW = dayjs(new Date()).format('YYYY-MM-DD');

function getColors(length) {
  const pallet = ['#9FE8AF', '#F0ADAD', '#FFD0B6', '#7AD8DE'];
  const colors = [];

  for (let i = 0; i < length; i += 1) {
    colors.push(pallet[i % pallet.length]);
  }

  return colors;
}

/**
 * Widget config
 * @type {({size: number, columns: [{dataIndex: string, title: string}, {dataIndex: string, title: string}, {customRender: function({text?: *}): string|string, dataIndex: string, title: string}, {dataIndex: string, title: string}], action: string, permission: string, role: string|undefined, id: number, title: TranslateResult, type: string}|{size: number, columns: [{dataIndex: string, title: string}, {dataIndex: string, title: string}, {customRender: function({text?: *}): string|string, dataIndex: string, title: string}, {dataIndex: string, title: string}], action: string, permission: string, id: number, title: TranslateResult, type: string}|{size: number, payload: {dateTo: string, dateFrom: string}, columns: [{dataIndex: string, title: string}, {dataIndex: string, title: string}, {customRender: function({record: *}): (string|string), title: string}, {customRender: function({record: *}): (*|string), title: string}], action: string, permission: string, id: number, title: TranslateResult, type: string}|{size: number, columns: [{slots: {customRender: string}, dataIndex: string, title: string}, {slots: {customRender: string}, dataIndex: string, title: string, key: string}, {slots: {customRender: string}, dataIndex: string, title: string}, {slots: {customRender: string}, dataIndex: string, title: string, key: string}], action: string, permission: string, id: number, title: TranslateResult, type: string}|{size: number, columns: [{slots: {customRender: string}, dataIndex: string, title: string}, {slots: {customRender: string}, dataIndex: string, title: string, key: string}, {slots: {customRender: string}, dataIndex: string, title: string}, {slots: {customRender: string}, dataIndex: string, title: string, key: string}], action: string, permission: string, id: number, title: TranslateResult, type: string})[]}
 * id: Widget unique identity
 * size: widget's width. ENUM(1, 2, 3, 4),  1 = 25%, 2 = 50%, 3 = 75%, 4 = 100%
 * type: Data display mode. ENUM('table', 'chart', 'summary')
 * permission: Permission required to access widget
 * title: widget title
 * column: Columns are required when the type is table
 * action: vuex action, Used to fetch data
 * payload:  action's payload, optional
 */

const widgets = [
  {
    id: 1,
    size: 2,
    permission: 'view-out-of-order',
    title: i18n.global.t('Out of Order'),
    type: 'table',
    url: '/out-of-order',
    columns: [
      {
        title: i18n.global.t('Out of Order Name'),
        dataIndex: 'name',
      },
      {
        title: i18n.global.t('Property'),
        dataIndex: 'property.abbreviation',
      },
      {
        title: i18n.global.t('Reported time'),
        dataIndex: 'createdAt',
        customRender: ({ text }) => date(text, { format: getDatetimeFormat() }),
      },
      {
        title: i18n.global.t('Status'),
        dataIndex: 'status',
        customRender: ({ text }) => i18n.global.t(text),
      },
    ],
    action: 'out-of-order/list',
  },
  {
    id: 2,
    size: 2,
    permission: 'view-lost-and-found',
    title: i18n.global.t('Lost & Found'),
    type: 'table',
    url: '/lost-founds',
    columns: [
      {
        title: i18n.global.t('Lost & Found Name'),
        dataIndex: 'name',
      },
      {
        title: i18n.global.t('Property'),
        dataIndex: 'property.abbreviation',
      },
      {
        title: i18n.global.t('Checkout date'),
        dataIndex: 'checkoutDate',
        customRender: ({ text }) => date(text, { format: getDateFormat() }),
      },
      {
        title: i18n.global.t('Status'),
        dataIndex: 'status',
        customRender: ({ text }) => i18n.global.t(text),
      },
    ],
    action: 'lost-founds/list',
  },
  {
    id: 3,
    size: 2,
    permission: 'view-room-assignment',
    title: i18n.global.t('Room Assignment'),
    type: 'table',
    url: '/room-assignment',
    columns: [
      {
        title: i18n.global.t('Property'),
        dataIndex: 'room.property.abbreviation',
      },
      {
        title: i18n.global.t('Room Name'),
        dataIndex: 'room.name',
      },
      {
        title: i18n.global.t('Cleaning time'),
        customRender: ({ record }) => {
          const { roomAssignments } = record;
          if (roomAssignments && roomAssignments.length) {
            return `${date(roomAssignments[0].cleaningDate, { format: getDateFormat() })} ${roomAssignments[0].cleaningTime}`;
          }
          return '-';
        },
      },
      {
        title: i18n.global.t('Status'),
        customRender: ({ record }) => {
          const { roomAssignments } = record;
          if (roomAssignments && roomAssignments.length) {
            return roomAssignments[0].status;
          }
          return '-';
        },
      },
    ],
    action: 'room-assignments/list',
    payload: {
      dateFrom: NOW,
      dateTo: dayjs(NOW).add(14, 'days').format('YYYY-MM-DD'),
    },
  },
  {
    id: 4,
    size: 2,
    permission: 'view-incident',
    title: i18n.global.t('Incident'),
    type: 'table',
    url: '/incidents',
    columns: [
      {
        title: i18n.global.t('Incident Name'),
        dataIndex: 'name',
      },
      {
        title: i18n.global.t('Property'),
        dataIndex: 'property.abbreviation',
      },
      {
        title: i18n.global.t('Reported time'),
        dataIndex: 'occurredAt',
        customRender: ({ text }) => date(text, { format: getDatetimeFormat() }),
      },
      {
        title: i18n.global.t('Status'),
        dataIndex: 'status',
        customRender: ({ text }) => i18n.global.t(text),
      },
    ],
    action: 'incidents/list',
  },
  {
    id: 5,
    size: 4,
    mobileTableWidth: '1024px',
    permission: 'view-reservation',
    title: i18n.global.t('Today’s checkin'),
    type: 'table',
    url: '/reservations',
    columns: [
      {
        title: i18n.global.t('Property'),
        dataIndex: 'property.abbreviation',
      },
      {
        title: i18n.global.t('Room Name'),
        customRender: ({ record }) => (record.reservedRoom ? record.reservedRoom.roomCode : '-'),
      },
      {
        title: i18n.global.t('Reservation ID'),
        dataIndex: 'reservationNumber',
      },
      {
        title: i18n.global.t('Check In'),
        dataIndex: 'checkin',
        customRender: ({ text }) => date(text, { format: getDateFormat() }),
      },
      {
        title: i18n.global.t('Check Out'),
        dataIndex: 'checkout',
        customRender: ({ text }) => date(text, { format: getDateFormat() }),
      },
      {
        title: i18n.global.t('Guest Name'),
        customRender: ({ record }) => (record.reservedRoom && record.reservedRoom.guests[0].surname ? record.reservedRoom.guests[0].surname : '-'),
      },
      {
        title: i18n.global.t('Check in status'),
        dataIndex: 'status',
        customRender: ({ text }) => i18n.global.t(text),
      },
    ],
    action: 'reservations/list',
    payload: {
      checkin: `${NOW},${NOW}`,
    },
  },
  {
    id: 6,
    size: 2,
    permission: 'view-payment',
    title: i18n.global.t('Payments'),
    type: 'table',
    url: '/payments',
    columns: [
      {
        title: i18n.global.t('From Partner'),
        dataIndex: 'fromPartner.name',
      },
      {
        title: i18n.global.t('Status'),
        dataIndex: 'status',
        customRender: ({ text }) => i18n.global.t(text),
      },
      {
        title: i18n.global.t('Request date'),
        dataIndex: 'requestDate',
        customRender: ({ text }) => date(text, { format: getDateFormat() }),
      },
      {
        title: i18n.global.t('Fee Type'),
        dataIndex: 'feeType',
        customRender: ({ text }) => i18n.global.t(text),
      },

    ],
    action: 'payments/list',
  },
  {
    id: 7,
    size: 2,
    permission: 'view-reservation',
    title: i18n.global.t('New Reservations'),
    type: 'table',
    url: '/reservations',
    columns: [
      {
        title: i18n.global.t('Property'),
        dataIndex: 'property.abbreviation',
      },
      {
        title: i18n.global.t('Reservation ID'),
        dataIndex: 'reservationNumber',
      },
      {
        title: i18n.global.t('Check In'),
        dataIndex: 'checkin',
        customRender: ({ text }) => date(text, { format: getDateFormat() }),
      },
      {
        title: i18n.global.t('Check Out'),
        dataIndex: 'checkout',
        customRender: ({ text }) => date(text, { format: getDateFormat() }),
      },
    ],
    action: 'reservations/list',
  },
  {
    id: 8,
    size: 1,
    permission: 'view-reservation',
    title: i18n.global.t('Reservation summary'),
    type: 'summary',
    action: 'reservations/summary',
  },
  {
    id: 9,
    size: 3,
    permission: 'view-reservation',
    title: i18n.global.t('Monthly Reservation Number'),
    type: 'chart',
    url: '/reservations',
    chart: 'BarChart',
    formatter: (data) => {
      const list = data && data.list ? data.list : [];
      const colors = list.map(() => '#12A3AC');
      return {
        labels: list.map((i) => date(i.month, { format: 'MMM YYYY' })),
        datasets: [{
          label: i18n.global.t('Monthly Reservation Number'),
          data: list.map((i) => i.count),
          backgroundColor: colors,
          borderColor: colors,
          borderWidth: 0,
        }],
      };
    },
    action: 'reservations/monthly',
  },
  {
    id: 10,
    size: 2,
    permission: 'view-alert',
    title: i18n.global.t('Alert'),
    type: 'table',
    url: '/alerts',
    columns: [
      {
        title: i18n.global.t('Alert'),
        customRender: ({ record }) => h(AlertTitle, { record }),
      },
      {
        title: i18n.global.t('Property'),
        dataIndex: 'property.abbreviation',
      },
      {
        title: i18n.global.t('Timestamp'),
        dataIndex: 'createdAt',
        customRender: ({ text }) => date(text, { format: getDateFormat() }),
      },
    ],
    action: 'alerts/list',
  },
  {
    id: 11,
    size: 2,
    permission: 'view-reservation',
    title: i18n.global.t('Reservation Number by OTA'),
    type: 'chart',
    url: '/reservations',
    chart: 'PieChart',
    options: {
      legend: {
        position: 'left',
      },
    },
    formatter: (data) => {
      const list = data && data.list ? data.list : [];
      return {
        labels: list.map((i) => i.ota),
        datasets: [{
          label: i18n.global.t('Reservation Number by OTA'),
          backgroundColor: getColors(list.length),
          data: list.map((i) => i.count),
          hoverOffset: 4,
        }],
      };
    },
    action: 'reservations/countGroupByOta',
    payloadSelect: {
      options: [
        { label: i18n.global.t('past 30 days'), value: dayjs().subtract(30, 'days').format('YYYY-MM-DD') },
        { label: i18n.global.t('past 1 year'), value: dayjs().subtract(1, 'years').format('YYYY-MM-DD') },
        { label: i18n.global.t('all time'), value: '' },
      ],
      payloadKey: 'from',
      default: '',
    },
  },
  {
    id: 12,
    size: 4,
    permission: 'view-hc-task',
    title: i18n.global.t('Housekeeper Task List'),
    type: 'table',
    url: '/housekeeper-tasks',
    columns: [
      {
        title: i18n.global.t('Housekeeper Company'),
        dataIndex: 'partner.name',
      },
      {
        title: i18n.global.t('Housekeeper'),
        customRender: ({ record }) => {
          const { user } = record;

          if (!user) {
            return '';
          }

          return getFullname({ fname: user.firstName, lname: user.lastName });
        },
      },
      {
        title: i18n.global.t('Assigned Status'),
        dataIndex: 'status',
      },
      {
        title: i18n.global.t('Cleaning time'),
        customRender: ({ record }) => `${date(record.cleaningDate, { format: getDateFormat() })} ${record.cleaningTime || ''}`,
      },
      {
        title: i18n.global.t('Finished time'),
        customRender: ({ record }) => date(record.completedAt, { format: getDateFormat() }),
      },
      {
        title: i18n.global.t('Checkout Status'),
        dataIndex: 'checkoutStatus',
      },
      {
        title: i18n.global.t('Property'),
        dataIndex: 'room.property.name',
      },
      {
        title: i18n.global.t('Room Name'),
        dataIndex: 'room.name',
      },
      {
        title: i18n.global.t('Housekeeping Status'),
        customRender: ({ record }) => {
          const today = date(new Date(), { format: 'YYYY-MM-DD' });
          const completeDate = record.completedAt ? date(record.completedAt, { format: 'YYYY-MM-DD' }) : '';
          if (!record.completedAt) return 'no';
          if (today === completeDate) return 'in-progress';
          if (record.completedAt) return 'yes';

          return '';
        },
      },

    ],
    action: 'housekeeper-tasks/list',
  },
  {
    id: 13,
    size: 2,
    permission: 'view-room-assignment',
    title: i18n.global.t('Assignment Request'),
    type: 'table',
    url: '/assignment-request',
    columns: [
      {
        title: i18n.global.t('Property'),
        dataIndex: 'room.property.name',
      },
      {
        title: i18n.global.t('Room Name'),
        dataIndex: 'room.name',
      },
      {
        title: i18n.global.t('Checkout Date'),
        dataIndex: 'checkoutDate',
      },
      {
        title: i18n.global.t('Checkout Time'),
        dataIndex: 'checkoutTime',
        customRender: ({ record }) => {
          const property = record.room && record.room.property;
          return (property.policies && property.policies.checkOutTime) || '–';
        },
      },
    ],
    action: 'assignment-request/list',
  },
];

export const getDefaultWidgets = (permissions, roles) => widgets.filter((widget) => {
  // if widget has no role specific, check against user permissions
  if (!widget.role) {
    return permissions.indexOf(widget.permission) !== -1;
  }
  // if widget has role specific, check against user roles
  return roles.indexOf(widget.role) !== -1;
});

/* eslint-disable */
export const getDefaultWidgetsIds = (permissions, roles) => getDefaultWidgets(permissions, roles).map((w) => w.id);

export const getWidgetsByIds = (ids, permissions, roles) => ids.map((id) => getDefaultWidgets(permissions, roles).find((w) => w.id === id)).filter(Boolean);
