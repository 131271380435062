import Cookies from 'js-cookie';

const TokenKey = 'lang';

export function getDefaultLang() {
  return Cookies.get(TokenKey) || 'en';
}

export function setDefaultLang(token) {
  return Cookies.set(TokenKey, token);
}

export function removeDefaultLang() {
  return Cookies.remove(TokenKey);
}
