import {
  list, add, del, get, update,
  categories, subCategories,
} from '@/api/facilities';

const state = {
  loading: false,
  facility: undefined,
};

const mutations = {
  SET_LOADING: (state, payload) => {
    state.loading = payload;
  },
  SET_FACILITY: (state, payload) => {
    state.facility = payload;
  },
};

const actions = {
  list(_, payload) {
    return list(payload);
  },
  update(_, payload) {
    return update(payload);
  },
  delete(_, payload) {
    return del(payload);
  },
  add(_, payload) {
    return add(payload);
  },
  getCategory(_, payload) {
    return categories(payload);
  },
  getSubCategory(_, payload) {
    return subCategories(payload);
  },
  async get({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      commit('SET_FACILITY', undefined);
      const res = await get(payload);
      commit('SET_FACILITY', res);
      commit('SET_LOADING', false);
      return res;
    } catch (error) {
      commit('SET_LOADING', false);
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
