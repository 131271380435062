import {
  get,
  result,
} from '@/api/simulations/simulation-spdb';

const actions = {
  get(_, payload) {
    return get(payload);
  },
  result(_, payload) {
    return result(payload);
  },
};

export default {
  namespaced: true,
  actions,
};
