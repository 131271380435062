import {
  add, edit, deletes, list,
} from '@/api/rms/gmsTargets';

const state = {
  gmsTargets: undefined,
  loading: undefined,
};

const mutations = {
  SET_GMS_TARGET: (state, payload) => {
    state.gmsTargets = payload;
  },
  SET_LOADING: (state, payload) => {
    state.loading = payload;
  },
};

const actions = {
  list({ commit }, params) {
    commit('SET_LOADING', true);
    return new Promise((resolve) => {
      resolve(list(params));
    }).finally(() => {
      commit('SET_LOADING', false);
    });
  },
  add(_, data) {
    return add(data);
  },
  edit(_, data) {
    return edit(data);
  },
  delete(_, data) {
    return deletes(data);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
