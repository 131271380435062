import { roles } from '@/api/role';

const state = {};
const mutations = {};

const actions = {
  roles() {
    return roles();
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
