import { getProviderIds, transformItems } from '@/store/modules/upsell-items';
import {
  names as providerName,
} from '@/api/partners';
import {
  get, list, cancelOrder, update, listItem,
} from '@/api/oms/upsell-item-orders';
import { get as getReservationDetail } from '@/api/reservations';
import { get as getUserInfo } from '@/api/user';
import { getFullname } from '@/utils/users';
import checkRole from '@/utils/role';
import { ROLES } from '@/config/roles';

const state = {
  loading: false,
  upsellItemOrder: undefined,
};

const mutations = {
  SET_LOADING: (state, payload) => {
    state.loading = payload;
  },
  SET_UPSELL_ITEM_ORDER: (state, payload) => {
    state.upsellItemOrder = payload;
  },
};

const actions = {
  // Get order detail
  async get({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      commit('SET_UPSELL_ITEM_ORDER', undefined);
      const { data: { order: upsellItemOrder } } = await get(payload);
      const { data: itemList } = await listItem(payload);
      let reservationRes = {};
      if (upsellItemOrder == null) {
        throw new Error('Order does not exist');
      }
      if (!checkRole([ROLES.HOUSEKEEPER]) && upsellItemOrder.reservationId) {
        reservationRes = await getReservationDetail({ id: upsellItemOrder.reservationId });
      }
      if (upsellItemOrder.cancellationBy) {
        const {
          firstName: fname,
          lastName: lname,
        } = await getUserInfo({ uid: upsellItemOrder.cancellationBy });
        upsellItemOrder.cancelledBy = getFullname({ fname, lname });
      }
      upsellItemOrder.reservation = reservationRes;
      upsellItemOrder.items = itemList;
      commit('SET_UPSELL_ITEM_ORDER', upsellItemOrder);
      commit('SET_LOADING', false);
      return upsellItemOrder;
    } catch (error) {
      commit('SET_LOADING', false);
      throw error;
    }
  },
  // Upsell item order list
  async list(_, params) {
    const items = await list(params);
    const providerIds = getProviderIds(items?.data?.rows || []);

    const providerNames = await providerName({
      ids: providerIds,
      infinite: true,
    });

    items.data.rows = transformItems(items?.data?.rows, providerNames.list);

    return items;
  },

  // Cancel order
  cancel(_, payload) {
    return cancelOrder(payload);
  },

  // Update order item
  update(_, payload) {
    return update(payload);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
