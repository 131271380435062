import {
  add,
  addImages,
  list,
  del,
  update,
} from '@/api/oms/upsell-items';
import {
  names,
} from '@/api/partners';

const state = {
  loading: false,
  upsellItem: undefined,
};

const mutations = {
  SET_LOADING: (state, payload) => {
    state.loading = payload;
  },
  SET_ITEM: (state, payload) => {
    state.upsellItems = payload;
  },
};

/**
 * gives upsell items array and return array of their provider ids
 * @param rows
 * @returns {*[]}
 */
export function getProviderIds(rows) {
  const ids = [];

  for (let i = 0; i < rows.length; i += 1) {
    for (let j = 0; j < rows[i].providers.length; j += 1) {
      ids.push(rows[i].providers[j].providerId);
    }
  }

  return [...new Set(ids)];
}

/**
 * add name property to the upsell item providers
 * @param rows
 * @param providerNames
 * @returns {*}
 */
export function transformItems(rows, providerNames) {
  for (let i = 0; i < rows.length; i += 1) {
    const { providers } = rows[i];
    for (let j = 0; j < providers.length; j += 1) {
      const provider = providers[j];
      rows[i].providers[j].name = providerNames.find((p) => p.id === provider.providerId)?.name;
    }
  }

  return rows;
}

const actions = {
  // upsells list
  async list(_, params) {
    const items = await list(params);
    const providerIds = getProviderIds(items.data.rows);

    const providerNames = await names({
      ids: providerIds,
      infinite: true,
    });

    items.data.rows = transformItems(items.data.rows, providerNames.list);

    return items;
  },
  // upsells add
  add(_, data) {
    return add(data);
  },
  // upsells add images
  addImages(_, data) {
    return addImages(data);
  },
  // upsells delete
  del(_, id) {
    return del(id);
  },
  // upsell item update
  update(_, data) {
    return update(data);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
