import {
  list,
  names,
  add,
  get,
  update,
  useRoomType,
  uploadLayout,
  deleteProperty,
  owners,
  propertyOwners,
  addOwner,
  removeOwner,
  housekeepingSchedule,
  listAnshinAccommodations,
  listAnshinGroups,
  anshinGroupById,
  getFee,
  listAnshinAccounts,
  listNeppanAccounts,
  getPriceAutoReflection,
} from '@/api/properties';

const state = {
  loading: false,
  property: undefined,
};

const mutations = {
  SET_LOADING: (state, payload) => {
    state.loading = payload;
  },
  SET_PROPERTY: (state, payload) => {
    state.property = payload;
  },
};

const actions = {
  list(_, payload) {
    return list(payload);
  },
  names(_, payload) {
    return names(payload);
  },
  getFee(_, payload) {
    return getFee(payload);
  },
  update(_, payload) {
    return update(payload);
  },
  useRoomType(_, payload) {
    return useRoomType(payload);
  },
  uploadLayout(_, payload) {
    return uploadLayout(payload);
  },
  delete(_, payload) {
    return deleteProperty(payload);
  },
  add(_, payload) {
    return add(payload);
  },
  owners(_, payload) {
    return owners(payload);
  },
  propertyOwners(_, payload) {
    return propertyOwners(payload);
  },
  addOwner(_, payload) {
    return addOwner(payload);
  },
  removeOwner(_, payload) {
    return removeOwner(payload);
  },
  async get({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      commit('SET_PROPERTY', undefined);
      const res = await get(payload);
      commit('SET_PROPERTY', res);
      commit('SET_LOADING', false);
      return res;
    } catch (error) {
      commit('SET_LOADING', false);
      throw error;
    }
  },
  priceAutoReflection(_, { propertyId }) {
    return getPriceAutoReflection({ id: propertyId });
  },
  housekeepingSchedule(_, { propertyId, params }) {
    return housekeepingSchedule(propertyId, params);
  },
  listAnshinAccommodations(_, params) {
    return listAnshinAccommodations(params);
  },
  listAnshinGroups(_, params) {
    return listAnshinGroups(params);
  },
  anshinGroupById(_, params) {
    return anshinGroupById(params);
  },
  listAnshinAccounts() {
    return listAnshinAccounts();
  },
  listNeppanAccounts(_, params) {
    return listNeppanAccounts(params);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
