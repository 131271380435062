import { lists, checks } from '@/api/rms/topline-checks';

const actions = {
  list(_, payload) {
    return lists(payload);
  },
  check(_, payload) {
    return checks(payload);
  },
};

export default {
  namespaced: true,
  actions,
};
