import {
  list,
  add,
  addItemImage,
  del,
  get,
  update,
  setRooms,
  deleteItems,
} from '@/api/inspection-list';
import { delInspectionRoom } from '@/api/rooms';

const state = {
  loading: false,
  inspection: undefined,
};

const mutations = {
  SET_LOADING: (state, payload) => {
    state.loading = payload;
  },
  SET_INSPECTION: (state, payload) => {
    state.inspection = payload;
  },
};

const actions = {
  list(_, payload) {
    return list(payload);
  },
  update(_, payload) {
    return update(payload);
  },
  delete(_, payload) {
    return del(payload);
  },
  deleteItems(_, payload) {
    return deleteItems(payload);
  },
  delInspectionRoom(_, payload) {
    return delInspectionRoom(payload);
  },
  add(_, payload) {
    return add(payload);
  },
  setRooms(_, payload) {
    return setRooms(payload);
  },
  addItemImage(_, payload) {
    return addItemImage(payload);
  },
  async get({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      commit('SET_INSPECTION', undefined);
      const res = await get(payload);
      commit('SET_INSPECTION', res);
      commit('SET_LOADING', false);
      return res;
    } catch (error) {
      commit('SET_LOADING', false);
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
