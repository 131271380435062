import { ROLES } from '@/config/roles';

export default [
  {
    path: '/revenue-management/settings',
    name: 'revenue-management-settings',
    component: () => import('@/views/revenue-management/settings/List'),
    meta: {
      roles: [ROLES.ADMIN, ROLES.SALES_PROMOTION],
    },
  },
  {
    path: '/revenue-management/settings/:id',
    name: 'revenue-management-settings-details',
    redirect: (to) => ({
      path: `/revenue-management/settings/${to.params.id}/overview`,
    }),
    component: () => import(/* webpackChunkName: "setting" */ '@/views/revenue-management/settings/Detail'),
    children: [
      {
        name: 'revenue-management-settings-overview',
        path: 'overview',
        component: () => import(/* webpackChunkName: "setting" */ '@/views/revenue-management/settings/Overview'),
        meta: {
          roles: [ROLES.ADMIN, ROLES.SALES_PROMOTION],
        },
      },
      {
        name: 'revenue-management-settings-competitor',
        path: 'competitor',
        component: () => import(/* webpackChunkName: "setting" */ '@/views/revenue-management/settings/Competitor'),
        meta: {
          roles: [ROLES.ADMIN, ROLES.SALES_PROMOTION],
        },
      },
      {
        name: 'revenue-management-settings-gms',
        path: 'gms',
        component: () => import(/* webpackChunkName: "setting" */ '@/views/revenue-management/settings/GmsTarget'),
        meta: {
          roles: [ROLES.ADMIN, ROLES.SALES_PROMOTION],
        },
      },
      {
        name: 'revenue-management-settings-fraction',
        path: 'fraction',
        component: () => import(/* webpackChunkName: "setting" */ '@/views/revenue-management/settings/Fraction'),
        meta: {
          roles: [ROLES.ADMIN, ROLES.SALES_PROMOTION],
        },
      },
    ],
  },
  {
    path: '/revenue-management/rates/per-room',
    name: 'revenue-management-rates-per-room',
    component: () => import('@/views/revenue-management/rates/PerRoom'),
    meta: {
      roles: [ROLES.ADMIN, ROLES.SALES_PROMOTION],
    },
  },
  {
    path: '/revenue-management/rates/per-person',
    name: 'revenue-management-rates-per-person',
    component: () => import('@/views/revenue-management/rates/PerPerson'),
    meta: {
      roles: [ROLES.ADMIN, ROLES.SALES_PROMOTION],
    },
  },
  {
    path: '/revenue-management/room-bottoms',
    name: 'revenue-management-room-bottoms',
    component: () => import('@/views/revenue-management/room-bottoms/List'),
    meta: {
      roles: [ROLES.ADMIN, ROLES.SALES_PROMOTION, ROLES.OPERATION],
    },
  },
  {
    path: '/revenue-management/toplines',
    name: 'revenue-management-toplines',
    component: () => import('@/views/revenue-management/topline/TopLines'),
    meta: {
      roles: [ROLES.ADMIN, ROLES.SALES_PROMOTION, ROLES.OPERATION, ROLES.SALES],
    },
  },
  {
    path: '/revenue-management/topline-checks',
    name: 'revenue-management-topline-checks',
    component: () => import('@/views/revenue-management/topline-check/ToplineChecks'),
    meta: {
      roles: [ROLES.ADMIN, ROLES.SALES_PROMOTION, ROLES.OPERATION],
    },
  },
  {
    path: '/revenue-management/locked-dates',
    name: 'revenue-management-locked-dates',
    component: () => import('@/views/revenue-management/locked-dates/List'),
    meta: {
      roles: [ROLES.ADMIN, ROLES.SALES_PROMOTION, ROLES.OPERATION],
    },
  },
];
