import { createApp } from 'vue';
import VueMq from 'vue3-mq';
import ElDatePicker from 'element-plus/lib/el-date-picker';
import ElementLocale from 'element-plus/lib/locale';

import App from './App';
import router from './router';
import store from './store';
import Antd from '@/plugins/antd';
import Essentials from '@/plugins/Essentials';
import Lazyload from '@/plugins/lazyload';
import i18n from '@/i18n';

import '@/plugins/rtx-fonts';
import '@/assets/css/ant.less';
import '@/assets/css/rtx-styles.scss';
import './registerServiceWorker';
import './permission';
import firebaseMessaging from './firebase';

ElementLocale.i18n(i18n.global.t);

const vm = createApp(App);
vm.use(Antd);
vm.use(Essentials);
vm.use(Lazyload, {
  observer: true,
  observerOptions: {
    rootMargin: '0px',
    threshold: 0.1,
  },
  loading: './img/loader.gif',
});

vm.use(VueMq, {
  breakpoints: {
    mobile: 769,
    desktop: Infinity,
  },
});

vm.use(ElDatePicker);

vm.use(i18n);

// set firebaseMessaging as global api
vm.config.globalProperties.$messaging = firebaseMessaging;

vm.use(store).use(router).mount('#app');
