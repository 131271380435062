import { externalVendors, list, saveData } from '@/api/iscm/price-auto-reflection';

const state = {
  loading: false,
  vendors: undefined,
  settings: undefined,
};

const mutations = {
  SET_LOADING: (state, payload) => {
    state.loading = payload;
  },

  SET_VENDORS: (state, payload) => {
    state.vendors = payload;
  },

  SET_SETTINGS: (state, payload) => {
    state.settings = payload;
  },

  GET_VENDORS: (state) => state.vendors,
};

const actions = {
  async listExternalVendors({ commit }) {
    commit('SET_LOADING', true);
    commit('SET_VENDORS', undefined);

    try {
      const data = await externalVendors();
      commit('SET_VENDORS', data);
      return data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async listSettings({ commit }, params) {
    commit('SET_LOADING', true);
    commit('SET_SETTINGS', undefined);

    try {
      const data = await list(params);
      commit('SET_SETTINGS', data);
      return data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  saveSettings(_, params) {
    return saveData(params);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
