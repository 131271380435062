import uniq from 'lodash/uniq';
import reduce from 'lodash/reduce';
import intersection from 'lodash/intersection';
import { search, getRooms } from '@/api/simulations/simulations';
import { CancelToken } from '@/utils/request';

const state = {
  results: undefined,
  isFetching: false,
  googleType: [],
  selectedPlace: undefined,
  panToPlace: undefined,
  rooms: undefined,
  isRoomFetching: false,
  fetchingQueue: {
    google: false,
    rakuten: false,
  },
  cancelSearch: [],
  cancelRooms: [],
  showResultPanel: false,
};

const mutations = {
  SET_RESULTS: (state, payload) => {
    state.results = payload;
  },
  UPDATE_RESULTS: (state, payload) => {
    state.results = { ...state.results, ...payload };
  },
  SET_IS_FETCHING: (state, payload) => {
    state.isFetching = payload;
  },
  SET_IS_ROOM_FETCHING: (state, payload) => {
    state.isRoomFetching = payload;
  },
  SET_GOOGLE_TYPE: (state, payload) => {
    state.googleType = payload;
  },
  SET_SELECTED_PLACE: (state, payload) => {
    state.selectedPlace = payload;
  },
  SET_PAN_TO_PLACE: (state, payload) => {
    state.panToPlace = payload;
  },
  RESET_SIMULATION: (state) => {
    state.showResultPanel = false;
    state.results = undefined;
    state.selectedPlace = undefined;
    state.googleType = [];
    state.panToPlace = undefined;
    state.rooms = undefined;
  },
  SET_ROOMS: (state, payload) => {
    state.rooms = payload;
  },
  SET_FETCHING_QUEUE: (state, payload) => {
    state.fetchingQueue = { ...state.fetchingQueue, ...payload };
  },
  SET_CANCEL_SEARCH: (state, payload) => {
    state.cancelSearch = payload;
  },
  SET_CANCEL_ROOMS: (state, payload) => {
    state.cancelRooms = payload;
  },
  SET_SHOW_RESULT_PANEL: (state, payload) => {
    state.showResultPanel = payload;
  },
};

const actions = {
  search({ commit, dispatch }, payload) {
    commit('SET_IS_FETCHING', true);
    commit('SET_FETCHING_QUEUE', {
      google: true,
      rakuten: true,
    });
    commit('SET_RESULTS', undefined);
    commit('SET_SELECTED_PLACE', undefined);
    commit('SET_GOOGLE_TYPE', []);
    commit('SET_PAN_TO_PLACE', undefined);
    commit('SET_CANCEL_SEARCH', []);
    commit('SET_SHOW_RESULT_PANEL', false);

    return new Promise((resolve, reject) => {
      const { coordinates, ...rest } = payload;
      const coordinatesArr = coordinates ? coordinates.split(',') : [];

      if (coordinatesArr.length !== 2) {
        commit('SET_IS_FETCHING', false);
        return reject(new Error('Wrong parameter'));
      }

      const promises = [];
      const cancelList = [];
      const sources = ['google', 'rakuten'];

      sources.forEach((source) => {
        const tokenSource = CancelToken.source();
        cancelList.push(tokenSource.cancel);

        const query = {
          cancelToken: tokenSource.token,
          lat: coordinatesArr[0],
          lng: coordinatesArr[1],
          apps: source,
          ...rest,
        };

        const promise = search(query)
          .then(({ data }) => {
            commit('UPDATE_RESULTS', data);
            commit('SET_FETCHING_QUEUE', { [source]: false });

            if (source === 'rakuten') {
              if (!data.rakuten) {
                data.rakuten = [];
              }

              if (data.rakuten.length > 0) {
                const rakutenHotelNos = data.rakuten
                  .filter((item) => !Number.isNaN(item.hotelBasicInfo.hotelMinCharge))
                  .map((item) => item.hotelBasicInfo.hotelNo);

                if (rakutenHotelNos.length > 0) {
                  dispatch('getRooms', { hotelNo: rakutenHotelNos });
                }
              }
            }
          })
          .catch(() => { commit('SET_FETCHING_QUEUE', { [source]: false }); });

        promises.push(promise);
      });

      commit('SET_CANCEL_SEARCH', cancelList);

      return Promise
        .all(promises)
        .then((resp) => {
          const data = resp.reduce((prev, current) => ({ ...prev, ...current }), {});

          commit('SET_SHOW_RESULT_PANEL', true);
          commit('SET_IS_FETCHING', false);

          resolve(data);
        })
        .catch((error) => {
          commit('SET_IS_FETCHING', false);
          reject(error);
        });
    });
  },
  getRooms({ commit }, params) {
    commit('SET_CANCEL_ROOMS', []);

    return new Promise((resolve, reject) => {
      commit('SET_IS_ROOM_FETCHING', true);

      const source = CancelToken.source();
      commit('SET_CANCEL_ROOMS', [source.cancel]);

      getRooms({ hotelNo: params.hotelNo.join(','), cancelToken: source.token })
        .then(({ data }) => {
          commit('SET_ROOMS', data);
          commit('SET_IS_ROOM_FETCHING', false);
          resolve(data);
        })
        .catch((error) => reject(error))
        .finally(() => {
          commit('SET_IS_ROOM_FETCHING', false);
        });
    });
  },
  cancel({ commit, state }) {
    state.cancelSearch.forEach((cancel) => cancel('Cancel search'));
    state.cancelRooms.forEach((cancel) => cancel('Cancel get rooms'));
    commit('SET_CANCEL_ROOMS', []);
    commit('SET_CANCEL_SEARCH', []);
    commit('SET_IS_ROOM_FETCHING', false);
    commit('SET_IS_FETCHING', false);
    commit('SET_SHOW_RESULT_PANEL', false);
  },
};

const getters = {
  googleList: (state) => (state.results && state.results.google ? state.results.google : []),
  googleMarkers: (state, getters) => getters.filteredGoogleList.map(
    (data) => data.geometry.location,
  ),
  rakutenList: (state) => (state.results && state.results.rakuten ? state.results.rakuten : []),
  rakutenMarkers: (state, getters) => getters.rakutenList.map(
    (data) => ({ lat: data.hotelBasicInfo.latitude, lng: data.hotelBasicInfo.longitude }),
  ),
  googleTypeOptions: (state, getters) => reduce(
    getters.googleList,
    (options, googlePlace) => uniq([...options, ...googlePlace.types]),
    [],
  ),
  filteredGoogleList: (state, getters) => (state.googleType.length
    ? getters.googleList.filter((item) => intersection(item.types, state.googleType).length > 0)
    : getters.googleList),
  isSearched: (state) => state.results !== undefined,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
