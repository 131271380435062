import { TYPES } from '@/config/alert';

export default {
  methods: {
    getTitle(record) {
      const { type, data } = record;

      if (!type) {
        return record.title;
      }

      return this.$t(`${type}-title`, data);
    },
    getDescription(record) {
      const { type, data } = record;

      if (!type) {
        return record.description;
      }

      return this.$t(`${type}-desc`, data);
    },
    showDate(record) {
      const { type } = record;
      const types = {
        [TYPES.APPROVED_ROOM_ASSIGNMENT]: true,
        [TYPES.CANCEL_RESERVATION]: true,
        [TYPES.NEW_ROOM_ASSIGNMENT]: true,
      };

      if (!type) {
        return false;
      }

      return types[type];
    },
    formatDate(date) {
      if (!date) {
        return '-';
      }

      return this.$filters.date(date, { format: this.$variables.dateFormat });
    },
  },
};
