import { ROLES } from '@/config/roles';
import { HIDE_SIMULATION_PAGE } from '@/utils/settings';

const searchRoute = HIDE_SIMULATION_PAGE ? [] : [
  {
    path: '/simulations/search',
    name: 'simulations-search',
    meta: {
      roles: [
        ROLES.ADMIN,
        ROLES.DATA_INSIGHT,
      ],
    },
    component: () => import(/* webpackChunkName: "search" */ '@/views/simulations/Search'),
  },
];

export default [
  ...searchRoute,
  {
    path: '/simulations',
    name: 'simulations-list',
    meta: {
      roles: [
        ROLES.ADMIN,
        ROLES.DATA_INSIGHT,
      ],
    },
    component: () => import(/* webpackChunkName: "simulations-list" */ '@/views/simulations/List'),
  },
  {
    path: '/simulations/new',
    name: 'simulations-new',
    meta: {
      roles: [
        ROLES.ADMIN,
        ROLES.DATA_INSIGHT,
      ],
    },
    component: () => import(/* webpackChunkName: "simulations-new" */ '@/views/simulations/New'),
  },
  {
    path: '/simulations/calculation-adjustment',
    name: 'simulation-calculation-adjustment',
    meta: {
      roles: [
        ROLES.ADMIN,
        ROLES.DATA_INSIGHT,
      ],
    },
    component: () => import(/* webpackChunkName: "simulation-calculation-adjustment" */ '@/views/simulations/CalculationAdjustment'),
  },
  {
    path: '/simulations/general-addons',
    name: 'simulation-general-addons',
    meta: {
      roles: [
        ROLES.ADMIN,
        ROLES.DATA_INSIGHT,
      ],
    },
    component: () => import(/* webpackChunkName: "simulation-general-addons" */ '@/views/simulations/GeneralAddonLists'),
  },
  {
    path: '/simulations/room-addons',
    name: 'simulation-room-addons',
    meta: {
      roles: [
        ROLES.ADMIN,
        ROLES.DATA_INSIGHT,
      ],
    },
    component: () => import(/* webpackChunkName: "simulation-room-addons" */ '@/views/simulations/RoomAddonLists'),
  },
  {
    path: '/simulations/prefecture-occ',
    name: 'simulation-prefecture-occ',
    meta: {
      roles: [
        ROLES.ADMIN,
        ROLES.DATA_INSIGHT,
      ],
    },
    component: () => import(/* webpackChunkName: "simulation-prefecture-occ" */ '@/views/simulations/PrefectureOccLists'),
  },
  {
    path: '/simulations/room-sizes',
    name: 'simulation-room-sizes',
    meta: {
      roles: [
        ROLES.ADMIN,
        ROLES.DATA_INSIGHT,
      ],
    },
    component: () => import(/* webpackChunkName: "simulation-room-size" */ '@/views/simulations/RoomSizeLists'),
  },
  {
    path: '/simulations/reservation-meals',
    name: 'simulation-reservation-meals',
    meta: {
      roles: [
        ROLES.ADMIN,
        ROLES.DATA_INSIGHT,
      ],
    },
    component: () => import(/* webpackChunkName: "simulation-room-size" */ '@/views/simulations/ReservationMealLists'),
  },
  {
    path: '/simulations/:id',
    name: 'simulations-show',
    meta: {
      roles: [
        ROLES.ADMIN,
        ROLES.DATA_INSIGHT,
      ],
    },
    component: () => import(/* webpackChunkName: "simulations-show" */ '@/views/simulations/Show'),
  },
  {
    path: '/simulations/extractions',
    name: 'simulation-extractions',
    meta: {
      roles: [
        ROLES.ADMIN,
        ROLES.DATA_INSIGHT,
      ],
    },
    component: () => import(/* webpackChunkName: "simulation-extractions" */ '@/views/simulations/Extractions'),
  },
  {
    path: '/simulations/extractions-property/:id',
    name: 'simulation-extractions-property',
    meta: {
      roles: [
        ROLES.ADMIN,
        ROLES.DATA_INSIGHT,
      ],
    },
    component: () => import(/* webpackChunkName: "simulation-extractions-property" */ '@/views/simulations/ExtractionsProperty'),
  },
];
