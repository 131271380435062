/**
 * this module will be automatically converted as "rms-settings"
 */
import {
  listRms,
  getRms,
  addRms,
  editRms,
  deleteRms,
  saveCompetitor,
} from '@/api/rms/settings';
import { get as getProperty } from '@/api/properties';

const state = {
  loading: false,
  rms: undefined,
  competitor: undefined,
};

const mutations = {
  SET_LOADING: (state, payload) => {
    state.loading = payload;
  },
  SET_RMS: (state, payload) => {
    state.rms = payload;
  },
  SET_COMPETITOR: (state, payload) => {
    state.competitor = payload;
  },
};

const actions = {
  listRms(_, params) {
    return listRms(params);
  },
  async getRms({ commit }, params) {
    commit('SET_LOADING', true);
    commit('SET_RMS', undefined);
    commit('SET_COMPETITOR', undefined);

    try {
      const result = await getRms(params);
      const { data } = result;

      if (data.competitor) {
        commit('SET_COMPETITOR', data.competitor);
        delete data.competitor;
      }

      if (data.propertyId) {
        const property = await getProperty({ id: data.propertyId });
        data.property = property;
      }

      commit('SET_RMS', result.data);

      return result;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  addRms(_, payload) {
    return addRms(payload);
  },
  editRms(_, payload) {
    return editRms(payload);
  },
  async updateRmsStatus({ commit, state }, payload) {
    await editRms({ id: state.rms.id, status: payload.status });
    commit('SET_RMS', { ...state.rms, status: payload.status });
  },
  deleteRms(_, params) {
    return deleteRms(params);
  },
  saveCompetitor(_, payload) {
    return saveCompetitor(payload);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
