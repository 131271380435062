import LANG from '@/components/languages';
import { getLocale } from '@/utils/locale';

export const getNavigatorLanguage = () => (navigator.languages && navigator.languages.length
  ? navigator.languages[0]
  : navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en');

export const getBase64 = function (img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

export const uploadableImage = function (image) {
  const baseUrl = `${process.env.VUE_APP_GCLOUD_STORAGE_PUBLIC_URL}/${process.env.VUE_APP_GCLOUD_STORAGE_BUCKET}`;

  return {
    ...image,
    // there are instances when Ant's Upload image check to return true for thumbnail
    type: 'image/',
    url: `${baseUrl}/${encodeURI(image.path)}`,
  };
};

export const appendImageUrl = function (images = []) {
  if (images.length) {
    return images.map(uploadableImage);
  }

  return [];
};

export const getResponseError = function (error) {
  let { message } = error;

  if (error.response && error.response.data) {
    const { data } = error.response;
    message = data.error ? data.error.message : message;
  }

  return message;
};

// remove null and undefined values from object
export const cleanObject = function (object) {
  return (
    Object.entries(object)
      // eslint-disable-next-line no-unused-vars
      .filter(([_, value]) => value !== null && value !== undefined)
      .reduce((out, [key, value]) => ({ ...out, [key]: value }), {})
  );
};

/**
 * this method is used to reserve column, row,
 * or words for Japanese locale
 * @returns {Boolean}
 */
export const reserveFormat = function () {
  return LANG[getLocale()] === LANG.ja;
};
/**
 * This method is used to check number type
 * @param {*} value
 * @returns {Boolean}
 */
export function isNumeric(value) {
  return /^\d+$/.test(value);
}
