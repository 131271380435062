import { createStore } from 'vuex';
import getters from './getters';
// https://webpack.js.org/guides/dependency-management/#requirecontext
const modulesFiles = require.context('./modules', true, /\.js$/);

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  // set './app.js' => 'app'
  const moduleName = modulePath
    .replace(/^\.\/(.*)\.\w+$/, '$1')
    .replace(/[/\\]/g, '-');

  const value = modulesFiles(modulePath);
  modules[moduleName] = value.default;
  return modules;
}, {});

export default createStore({
  state() {},
  mutations: {
    SHOW_FULLSCREEN_LOADER: (state, payload) => {
      state.fullScreenLoaderVisible = true;
      state.fullScreenLoaderTitle = payload;
    },
    HIDE_FULLSCREEN_LOADER: (state) => {
      state.fullScreenLoaderVisible = false;
      state.fullScreenLoaderTitle = '';
    },
  },
  actions: {},
  modules,
  getters,
});
