import Cookies from 'js-cookie';
import { getDefaultLang } from '@/utils/language';

const cookieKey = 'locale';
const localeMap = {
  en: 'en-US',
  ja: 'ja-JP',
};

export function getLocale() {
  return Cookies.get(cookieKey) || getDefaultLang();
}

export function setLocale(locale) {
  return Cookies.set(cookieKey, locale);
}

export function removeLocale() {
  return Cookies.remove(cookieKey);
}

export function getLocaleWithCountry(languageCode) {
  return localeMap[languageCode];
}
