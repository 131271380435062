import store from '@/store';

const TokenKey = 'token';
const ExpirationKey = 'expiration';

export function getToken() {
  return window.sessionStorage.getItem(TokenKey);
}

export function setToken(token) {
  return window.sessionStorage.setItem(TokenKey, token);
}

export function removeToken() {
  return window.sessionStorage.removeItem(TokenKey);
}

export function getTokenExpiration() {
  return window.sessionStorage.getItem(ExpirationKey);
}

export function setTokenExpiration(expiration) {
  return window.sessionStorage.setItem(ExpirationKey, expiration);
}

export function removeTokenExpiration() {
  return window.sessionStorage.removeItem(ExpirationKey);
}

export function logout(toPath) {
  store.dispatch('user/logout').then(() => {
    setTimeout(() => {
      let redirect = toPath || window.location.pathname;
      if (redirect.toLowerCase().includes('/change-password')) {
        redirect = '';
      }

      const loginUrl = redirect ? `/login?redirect=${redirect}` : '/login';

      window.location.replace(loginUrl);
    }, 100);
  });
}
