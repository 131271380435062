import {
  list,
  addDaysOff,
  update,
  daysOffList,
  updateDaysOff,
  deleteDaysOff,
} from '@/api/working-date';

const state = {
  loading: false,
  item: undefined,
};

const mutations = {
  SET_LOADING: (state, payload) => {
    state.loading = payload;
  },
  SET_ITEM: (state, payload) => {
    state.item = payload;
  },
};

const actions = {
  list(_, payload) {
    return list(payload);
  },
  update(_, payload) {
    return update(payload);
  },
  addDaysOff(_, payload) {
    return addDaysOff(payload);
  },
  updateDaysOff(_, payload) {
    return updateDaysOff(payload);
  },
  getDaysOff(_, payload) {
    return daysOffList(payload);
  },
  deleteDaysOff(_, payload) {
    return deleteDaysOff(payload);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
