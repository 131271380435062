import { createRouter, createWebHistory } from 'vue-router';
import { setLocale } from '@/utils/locale';
import simulationRoutes from './simulations';
import iscmRoutes from './iscm';
import { ROLES, INTERNAL, REPORTS } from '@/config/roles';
import rmsRoutes from '@/router/rms';

/**
 * meta : {
    roles: ['ADMIN','SALES']    control the page roles (you can set multiple roles)
    title: 'title'               page title
  }
 */
export const constantRoutes = [
  ...rmsRoutes,
  ...iscmRoutes,
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "simulation" */ '@/views/home/Home'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/user/Login'),
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import(/* webpackChunkName: "forgot" */ '@/views/user/ForgotPassword'),
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import(/* webpackChunkName: "forgot" */ '@/views/user/ResetPassword'),
    beforeEnter: (to, from, next) => {
      if (!to.query.token) next('/login');
      else {
        if (to.query.lang && ['en', 'ja'].includes(to.query.lang)) {
          setLocale(to.query.lang);
          window.location.replace(`${to.path}?token=${to.query.token}`);
        }
        next();
      }
    },
  },
  {
    path: '/set-initial-password',
    name: 'set-initial-password',
    component: () => import(/* webpackChunkName: "set-password" */ '@/views/user/SetInitialPassword'),
    beforeEnter: (to, from, next) => {
      if (!to.query.token) next('/login');
      else {
        if (to.query.lang && ['en', 'ja'].includes(to.query.lang)) {
          setLocale(to.query.lang);
          window.location.replace(`${to.path}?token=${to.query.token}`);
        }
        next();
      }
    },
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: () => import(/* webpackChunkName: "change-password" */ '@/views/user/ChangePassword'),
  },
  ...simulationRoutes,
  {
    path: '/users',
    name: 'users',
    meta: {
      roles: [
        ROLES.ADMIN, ROLES.SALES, ROLES.OPERATION,
        ROLES.CUSTOMER_SUPPORT, ROLES.PROPERTY_OWNER,
        ROLES.HOUSEKEEPING_COMPANY, ROLES.HOUSEKEEPER,
        ROLES.SALES_PROMOTION,
        ROLES.DATA_INSIGHT,
      ],
    },
    component: () => import(/* webpackChunkName: "users" */ '@/views/user/Users'),
  },
  {
    path: '/users/add',
    name: 'add-user',
    meta: {
      roles: [
        ROLES.ADMIN, ROLES.SALES, ROLES.OPERATION,
        ROLES.HOUSEKEEPING_COMPANY, ROLES.PROPERTY_OWNER,
        ROLES.SALES_PROMOTION,
      ],
    },
    component: () => import(/* webpackChunkName: "users" */ '@/views/user/AddUser'),
  },
  {
    path: '/users/edit/:uid',
    name: 'edit-user',
    props: true,
    meta: {
      roles: [
        ROLES.ADMIN, ROLES.SALES, ROLES.OPERATION,
        ROLES.HOUSEKEEPING_COMPANY, ROLES.PROPERTY_OWNER,
        ROLES.SALES_PROMOTION,
        ROLES.DATA_INSIGHT,
      ],
    },
    component: () => import(/* webpackChunkName: "users" */ '@/views/user/EditUser'),
  },
  {
    path: '/properties',
    name: 'properties',
    meta: {
      roles: [
        ROLES.ADMIN, ROLES.OPERATION,
        ROLES.CUSTOMER_SUPPORT, ROLES.PROPERTY_OWNER,
        ROLES.HOUSEKEEPING_COMPANY, ROLES.HOUSEKEEPER,
        ROLES.SALES_PROMOTION,
      ],
    },
    component: () => import(/* webpackChunkName: "properties" */ '@/views/properties/Properties'),
  },
  {
    path: '/properties/add',
    name: 'properties-add',
    meta: {
      roles: [
        ROLES.ADMIN,
        ROLES.OPERATION,
        ROLES.PROPERTY_OWNER,
        ROLES.SALES_PROMOTION,
      ],
    },
    component: () => import(/* webpackChunkName: "properties" */ '@/views/properties/Add'),
  },
  {
    path: '/property/:id',
    name: 'property',
    redirect: '/property/:id/overview',
    component: () => import(/* webpackChunkName: "property" */ '@/views/properties/Property'),
    meta: {
      roles: [
        ROLES.ADMIN, ROLES.OPERATION, ROLES.CUSTOMER_SUPPORT,
        ROLES.PROPERTY_OWNER, ROLES.HOUSEKEEPING_COMPANY,
        ROLES.HOUSEKEEPER, ROLES.SALES_PROMOTION,
      ],
    },
    children: [
      {
        name: 'property-overview',
        path: 'overview',
        component: () => import(/* webpackChunkName: "property" */ '@/views/properties/PropertyOverview'),
      },
      {
        name: 'property-property',
        path: 'property',
        component: () => import(/* webpackChunkName: "property" */ '@/views/properties/PropertyProperty'),
      },
      {
        name: 'property-rooms',
        path: 'rooms',
        component: () => import(/* webpackChunkName: "property" */ '@/views/properties/PropertyRoom'),
      },
      {
        name: 'property-out-of-order',
        path: 'out-of-order',
        component: () => import(/* webpackChunkName: "property" */ '@/views/properties/PropertyOutOfOrder'),
      },
      {
        name: 'property-room-type',
        path: 'room-type',
        component: () => import(/* webpackChunkName: "property" */ '@/views/properties/PropertyRoomType'),
      },
      {
        name: 'property-incident',
        path: 'incidents',
        component: () => import(/* webpackChunkName: "property" */ '@/views/properties/PropertyIncident'),
      },
      {
        name: 'property-facilities',
        path: 'facilities',
        component: () => import(/* webpackChunkName: "property" */ '@/views/properties/PropertyFacilities'),
      },
      {
        name: 'property-owners',
        path: 'owners',
        component: () => import(/* webpackChunkName: "property" */ '@/views/properties/PropertyOwners'),
      },
      {
        name: 'property-housekeeping-cycles',
        path: 'housekeeping-cycles',
        component: () => import(/* webpackChunkName: "property" */ '@/views/properties/PropertyHousekeepingCycles'),
      },
      {
        name: 'property-rate-plans',
        path: 'rate-plans',
        component: () => import(/* webpackChunkName: "property" */ '@/views/properties/PropertyRatePlans'),
      },
    ],
  },
  {
    name: 'property-room-add',
    path: '/property/:id/rooms/add',
    component: () => import(/* webpackChunkName: "property" */ '@/views/properties/RoomAdd'),
    meta: {
      roles: [
        ROLES.ADMIN,
        ROLES.OPERATION,
        ROLES.PROPERTY_OWNER,
        ROLES.SALES_PROMOTION,
      ],
    },
  },
  {
    name: 'property-room',
    path: '/property/:id/room/:rid',
    component: () => import(/* webpackChunkName: "room" */ '@/views/properties/Room'),
    meta: {
      roles: [
        ROLES.ADMIN, ROLES.OPERATION, ROLES.CUSTOMER_SUPPORT,
        ROLES.PROPERTY_OWNER, ROLES.HOUSEKEEPING_COMPANY,
        ROLES.HOUSEKEEPER, ROLES.SALES_PROMOTION,
      ],
    },
    redirect: '/property/:id/room/:rid/overview',
    children: [
      {
        name: 'room-overview',
        path: 'overview',
        component: () => import(/* webpackChunkName: "room" */ '@/views/properties/RoomOverview'),
      },
      {
        name: 'room-facilities',
        path: 'facilities',
        component: () => import(/* webpackChunkName: "room" */ '@/views/properties/RoomFacilities'),
      },
      {
        name: 'room-inspections',
        path: 'inspections',
        component: () => import(/* webpackChunkName: "room" */ '@/views/properties/RoomInspections'),
      },
    ],
  },
  {
    path: '/partners',
    name: 'partners',
    meta: {
      roles: [
        ROLES.ADMIN,
        ROLES.SALES,
        ROLES.OPERATION,
        ROLES.SALES_PROMOTION,
      ],
    },
    component: () => import(/* webpackChunkName: "partners" */ '@/views/partners/Partners'),
  },
  {
    path: '/partners/add',
    name: 'partners-add',
    meta: {
      roles: [
        ROLES.ADMIN,
        ROLES.SALES,
        ROLES.OPERATION,
        ROLES.SALES_PROMOTION,
      ],
    },
    component: () => import(/* webpackChunkName: "partners" */ '@/views/partners/Add'),
  },
  {
    path: '/partner/:id',
    name: 'partner',
    redirect: '/partner/:id/overview',
    component: () => import(/* webpackChunkName: "partner" */ '@/views/partners/Partner'),
    meta: {
      roles: [
        ROLES.ADMIN,
        ROLES.SALES,
        ROLES.OPERATION,
        ROLES.SALES_PROMOTION,
      ],
    },
    children: [
      {
        name: 'partner-overview',
        path: 'overview',
        component: () => import(/* webpackChunkName: "partner" */ '@/views/partners/PartnerOverview'),
      },
      {
        name: 'partner-pic',
        path: 'pic',
        component: () => import(/* webpackChunkName: "partner" */ '@/views/partners/PartnerPic'),
      },
      {
        name: 'partner-bank',
        path: 'bank',
        component: () => import(/* webpackChunkName: "partner" */ '@/views/partners/PartnerBank'),
      },
    ],
  },
  {
    path: '/payments',
    name: 'payments',
    meta: {
      roles: [
        ROLES.ADMIN,
        ROLES.SALES,
        ROLES.OPERATION,
        ROLES.SALES_PROMOTION,
      ],
    },
    component: () => import(/* webpackChunkName: "payments" */ '@/views/payments/Payments'),
  },
  {
    path: '/payments/add',
    name: 'payments-add',
    meta: {
      roles: [
        ROLES.ADMIN,
        ROLES.SALES,
        ROLES.OPERATION,
        ROLES.SALES_PROMOTION,
      ],
    },
    component: () => import(/* webpackChunkName: "payments" */ '@/views/payments/Add'),
  },
  {
    path: '/payments/:id/edit',
    name: 'payments-edit',
    meta: {
      roles: [
        ROLES.ADMIN,
        ROLES.SALES,
        ROLES.OPERATION,
        ROLES.SALES_PROMOTION,
      ],
    },
    component: () => import(/* webpackChunkName: "payments" */ '@/views/payments/Edit'),
  },
  {
    path: '/room-assignment',
    name: 'room-assignment',
    meta: {
      roles: [
        ROLES.ADMIN, ROLES.OPERATION,
        ROLES.CUSTOMER_SUPPORT, ROLES.PROPERTY_OWNER,
        ROLES.HOUSEKEEPING_COMPANY,
      ],
    },
    component: () => import(
      /* webpackChunkName: "room-assignment" */ '@/views/room-assignment/RoomAssignment'
    ),
  },
  {
    path: '/calendar',
    name: 'calendar',
    meta: {
      roles: [
        ROLES.ADMIN, ROLES.OPERATION,
        ROLES.CUSTOMER_SUPPORT, ROLES.PROPERTY_OWNER,
        ROLES.HOUSEKEEPING_COMPANY, ROLES.HOUSEKEEPER,
        ROLES.SALES_PROMOTION,
      ],
    },
    component: () => import(/* webpackChunkName: "calendar" */ '@/views/calendar/Calendar'),
  },
  {
    path: '/inspections',
    name: 'inspections',
    meta: {
      roles: [
        ROLES.ADMIN, ROLES.OPERATION,
        ROLES.CUSTOMER_SUPPORT, ROLES.PROPERTY_OWNER,
        ROLES.HOUSEKEEPING_COMPANY,
      ],
    },
    component: () => import(/* webpackChunkName: "inspections" */ '@/views/inspections/Inspections'),
  },
  {
    path: '/out-of-order',
    name: 'out-of-order',
    meta: {
      roles: [
        ROLES.ADMIN, ROLES.OPERATION,
        ROLES.CUSTOMER_SUPPORT, ROLES.PROPERTY_OWNER,
        ROLES.HOUSEKEEPING_COMPANY, ROLES.HOUSEKEEPER,
      ],
    },
    component: () => import(/* webpackChunkName: "out-of-order" */ '@/views/out-of-order/OutOfOrder'),
  },
  {
    path: '/out-of-order/:id/edit',
    name: 'out-of-order-edit',
    meta: {
      roles: [
        ROLES.ADMIN, ROLES.OPERATION, ROLES.PROPERTY_OWNER,
        ROLES.HOUSEKEEPING_COMPANY, ROLES.CUSTOMER_SUPPORT,
        ROLES.HOUSEKEEPER,
      ],
    },
    component: () => import(/* webpackChunkName: "out-of-order" */ '@/views/out-of-order/Edit'),
  },
  {
    path: '/out-of-order/add',
    name: 'out-of-order-add',
    meta: {
      roles: [
        ROLES.ADMIN, ROLES.OPERATION, ROLES.PROPERTY_OWNER,
        ROLES.HOUSEKEEPING_COMPANY, ROLES.CUSTOMER_SUPPORT,
        ROLES.HOUSEKEEPER,
      ],
    },
    component: () => import(/* webpackChunkName: "out-of-order" */ '@/views/out-of-order/Add'),
  },
  {
    path: '/incidents',
    name: 'incidents',
    meta: {
      roles: [
        ROLES.ADMIN, ROLES.OPERATION,
        ROLES.CUSTOMER_SUPPORT, ROLES.PROPERTY_OWNER,
        ROLES.HOUSEKEEPING_COMPANY, ROLES.HOUSEKEEPER,
      ],
    },
    component: () => import(/* webpackChunkName: "incidents" */ '@/views/incidents/Incident'),
  },
  {
    path: '/incidents/:id/edit',
    name: 'incidents-edit',
    meta: {
      roles: [
        ROLES.ADMIN, ROLES.OPERATION,
        ROLES.CUSTOMER_SUPPORT, ROLES.PROPERTY_OWNER,
        ROLES.HOUSEKEEPING_COMPANY, ROLES.HOUSEKEEPER,
      ],
    },
    component: () => import(/* webpackChunkName: "incidents" */ '@/views/incidents/Edit'),
  },
  {
    path: '/incidents/add',
    name: 'incidents-add',
    meta: {
      roles: [
        ROLES.ADMIN, ROLES.OPERATION,
        ROLES.CUSTOMER_SUPPORT, ROLES.PROPERTY_OWNER,
        ROLES.HOUSEKEEPING_COMPANY, ROLES.HOUSEKEEPER,
      ],
    },
    component: () => import(/* webpackChunkName: "incidents" */ '@/views/incidents/Add'),
  },
  {
    path: '/stocks',
    name: 'stocks',
    component: () => import(/* webpackChunkName: "room-stocks" */ '@/views/stocks/Index'),
    meta: {
      roles: [
        ROLES.ADMIN, ROLES.OPERATION,
        ROLES.CUSTOMER_SUPPORT, ROLES.PROPERTY_OWNER,
        ROLES.HOUSEKEEPING_COMPANY, ROLES.HOUSEKEEPER,
      ],
    },
  },
  {
    name: 'room-stocks',
    path: '/stocks/rooms/:id',
    component: () => import(/* webpackChunkName: "room-stocks" */ '@/views/stocks/RoomStocks'),
    meta: {
      roles: [
        ROLES.ADMIN, ROLES.OPERATION,
        ROLES.CUSTOMER_SUPPORT, ROLES.PROPERTY_OWNER,
        ROLES.HOUSEKEEPING_COMPANY, ROLES.HOUSEKEEPER,
      ],
    },
  },
  {
    path: '/housekeeper-tasks',
    name: 'housekeeper-tasks',
    meta: {
      roles: [
        ROLES.ADMIN, ROLES.OPERATION,
        ROLES.CUSTOMER_SUPPORT, ROLES.PROPERTY_OWNER,
        ROLES.HOUSEKEEPING_COMPANY, ROLES.HOUSEKEEPER,
      ],
    },
    component: () => import(
      /* webpackChunkName: "housekeeper-tasks" */ '@/views/housekeeper-tasks/HousekeeperTasks'
    ),
  },
  {
    path: '/housekeeper-task/:id',
    name: 'housekeeper-task',
    redirect: '/housekeeper-task/:id/overview',
    component: () => import(
      /* webpackChunkName: "housekeeper-task" */ '@/views/housekeeper-tasks/HousekeeperTask'
    ),
    meta: {
      roles: [
        ROLES.ADMIN, ROLES.OPERATION, ROLES.CUSTOMER_SUPPORT,
        ROLES.HOUSEKEEPING_COMPANY, ROLES.HOUSEKEEPER,
        ROLES.PROPERTY_OWNER,
      ],
    },
    children: [
      {
        name: 'housekeeper-task-overview',
        path: 'overview',
        component: () => import(
          /* webpackChunkName: "housekeeper-task" */ '@/views/housekeeper-tasks/HousekeeperTaskOverview'
        ),
      },
      {
        name: 'housekeeper-task-checklist',
        path: 'checklist',
        component: () => import(
          /* webpackChunkName: "housekeeper-task" */ '@/views/housekeeper-tasks/HousekeeperTaskChecklist'
        ),
      },
      {
        name: 'housekeeper-task-stock',
        path: 'stocks',
        component: () => import(
          /* webpackChunkName: "housekeeper-task" */ '@/views/housekeeper-tasks/HousekeeperTaskStock'
        ),
      },
      {
        name: 'housekeeper-task-lost-found',
        path: 'lost-found',
        component: () => import(
          /* webpackChunkName: "housekeeper-task" */ '@/views/housekeeper-tasks/HousekeeperTaskLostFound'
        ),
      },
      {
        name: 'housekeeper-task-out-of-order',
        path: 'out-of-order',
        component: () => import(
          /* webpackChunkName: "housekeeper-task" */ '@/views/housekeeper-tasks/HousekeeperTaskOutOfOrder'
        ),
      },
    ],
  },
  {
    name: 'lost-founds-add',
    path: '/lost-founds/add',
    meta: {
      roles: [
        ROLES.ADMIN, ROLES.OPERATION, ROLES.PROPERTY_OWNER,
        ROLES.HOUSEKEEPING_COMPANY, ROLES.CUSTOMER_SUPPORT,
        ROLES.HOUSEKEEPER,
      ],
    },
    component: () => import(/* webpackChunkName: "lost-found" */ '@/views/lost-found/LostFoundAdd'),
  },
  {
    path: '/lost-founds',
    name: 'lost-founds',
    meta: {
      roles: [
        ROLES.ADMIN, ROLES.OPERATION,
        ROLES.CUSTOMER_SUPPORT, ROLES.PROPERTY_OWNER,
        ROLES.HOUSEKEEPING_COMPANY, ROLES.HOUSEKEEPER,
      ],
    },
    component: () => import(/* webpackChunkName: "lost-found" */ '@/views/lost-found/LostFounds'),
  },
  {
    path: '/lost-founds/:id/edit',
    name: 'lost-found-edit',
    meta: {
      roles: [
        ROLES.ADMIN, ROLES.OPERATION, ROLES.PROPERTY_OWNER,
        ROLES.HOUSEKEEPING_COMPANY, ROLES.CUSTOMER_SUPPORT,
        ROLES.HOUSEKEEPER,
      ],
    },
    component: () => import(/* webpackChunkName: "lost-found" */ '@/views/lost-found/Edit'),
  },
  {
    path: '/alerts',
    name: 'alerts',
    meta: {
      roles: [
        ROLES.ADMIN, ROLES.OPERATION,
        ROLES.CUSTOMER_SUPPORT, ROLES.PROPERTY_OWNER,
        ROLES.HOUSEKEEPING_COMPANY, ROLES.HOUSEKEEPER,
        ROLES.SALES_PROMOTION,
      ],
    },
    component: () => import(/* webpackChunkName: "alerts" */ '@/views/alerts/Alerts'),
  },
  {
    name: 'alerts-add',
    path: '/alerts/add',
    meta: {
      roles: [
        ROLES.ADMIN, ROLES.OPERATION, ROLES.PROPERTY_OWNER,
        ROLES.HOUSEKEEPING_COMPANY, ROLES.CUSTOMER_SUPPORT,
        ROLES.HOUSEKEEPER, ROLES.SALES_PROMOTION,
      ],
    },
    component: () => import(/* webpackChunkName: "alerts" */ '@/views/alerts/Add'),
  },
  {
    path: '/working-date',
    name: 'working-date',
    meta: {
      roles: [
        ROLES.HOUSEKEEPER, ROLES.HOUSEKEEPING_COMPANY,
      ],
    },
    component: () => import(/* webpackChunkName: "working-date" */ '@/views/working-date/WorkingDate'),
  },
  {
    path: '/assignment-request',
    name: 'assignment-request',
    meta: {
      roles: [
        ROLES.ADMIN, ROLES.OPERATION, ROLES.CUSTOMER_SUPPORT,
        ROLES.HOUSEKEEPING_COMPANY, ROLES.PROPERTY_OWNER,
      ],
    },
    component: () => import(
      /* webpackChunkName: "working-date" */ '@/views/assignment-request/AssignmentRequest'
    ),
  },
  {
    path: '/system-logs',
    name: 'system-logs',
    redirect: '/system-logs/simulation',
    component: () => import(/* webpackChunkName: "system-logs" */ '@/views/system-logs/SystemLogs'),
    meta: {
      roles: [
        ROLES.ADMIN, ROLES.OPERATION, ROLES.SALES,
        ROLES.CUSTOMER_SUPPORT, ROLES.SALES_PROMOTION,
        ROLES.DATA_INSIGHT,
      ],
    },
    children: [
      {
        name: 'system-logs-simulation',
        path: 'simulation',
        component: () => import(/* webpackChunkName: "property" */ '@/views/system-logs/Simulation'),
      },
      {
        name: 'system-logs-housekeeping',
        path: 'housekeeping',
        component: () => import(/* webpackChunkName: "property" */ '@/views/system-logs/Housekeeping'),
      },
      {
        name: 'system-logs-user',
        path: 'user',
        component: () => import(/* webpackChunkName: "property" */ '@/views/system-logs/User'),
      },
      {
        name: 'system-logs-neppan',
        path: 'neppan',
        component: () => import(/* webpackChunkName: "property" */ '@/views/system-logs/Neppan'),
      },
      {
        name: 'system-logs-anshin',
        path: 'anshin',
        component: () => import(/* webpackChunkName: "property" */ '@/views/system-logs/Anshin'),
      },
    ],
  },
  {
    path: '/expenses',
    name: 'expenses',
    meta: {
      roles: [
        ROLES.ADMIN, ROLES.OPERATION, ROLES.CUSTOMER_SUPPORT,
        ROLES.HOUSEKEEPER, ROLES.HOUSEKEEPING_COMPANY,
      ],
    },
    component: () => import(/* webpackChunkName: "expenses" */ '@/views/expenses/Expenses'),
  },
  {
    path: '/expenses/:id/edit',
    name: 'expenses-edit',
    meta: {
      roles: [
        ROLES.ADMIN, ROLES.OPERATION, ROLES.HOUSEKEEPER,
        ROLES.HOUSEKEEPING_COMPANY, ROLES.CUSTOMER_SUPPORT,
        ROLES.SALES, ROLES.SALES_PROMOTION,
      ],
    },
    component: () => import(/* webpackChunkName: "expenses" */ '@/views/expenses/Edit'),
  },
  {
    path: '/expenses/add',
    name: 'expenses-add',
    meta: {
      roles: [
        ROLES.ADMIN, ROLES.OPERATION, ROLES.HOUSEKEEPER,
        ROLES.HOUSEKEEPING_COMPANY, ROLES.CUSTOMER_SUPPORT,
      ],
    },
    component: () => import(/* webpackChunkName: "out-of-order" */ '@/views/expenses/Add'),
  },
  {
    path: '/reservations',
    name: 'reservations',
    meta: {
      roles: [...INTERNAL, ROLES.SALES_PROMOTION],
    },
    component: () => import(/* webpackChunkName: "reservation" */ '@/views/reservations/Reservations'),
  },
  {
    path: '/reservations/calendar',
    name: 'reservations-calendar',
    meta: {
      roles: [...INTERNAL, ROLES.SALES_PROMOTION],
    },
    component: () => import(/* webpackChunkName: "reservation" */ '@/views/reservations/Calendar'),
  },
  {
    path: '/reservations/add',
    name: 'reservations-add',
    meta: {
      roles: [...INTERNAL, ROLES.SALES_PROMOTION],
    },
    component: () => import(/* webpackChunkName: "reservation" */ '@/views/reservations/Add'),
  },
  {
    path: '/reservation/:id',
    name: 'reservation',
    redirect: '/reservation/:id/basic-info',
    component: () => import(/* webpackChunkName: "reservation" */ '@/views/reservations/Reservation'),
    meta: {
      roles: [...INTERNAL, ROLES.SALES_PROMOTION],
    },
    children: [
      {
        name: 'reservation-basic-info',
        path: 'basic-info',
        component: () => import(/* webpackChunkName: "reservation" */ '@/views/reservations/BasicInfo'),
      },
      {
        name: 'reservation-booking-info',
        path: 'booking-info',
        component: () => import(/* webpackChunkName: "reservation" */ '@/views/reservations/BookingInfo'),
      },
      {
        name: 'reservation-room-guest',
        path: 'room-guest',
        component: () => import(/* webpackChunkName: "reservation" */ '@/views/reservations/RoomGuest'),
      },
      {
        name: 'reservation-other-info',
        path: 'other-info',
        component: () => import(/* webpackChunkName: "reservation" */ '@/views/reservations/OtherInfo'),
      },
      {
        name: 'reservation-email-log',
        path: 'email-log',
        component: () => import(/* webpackChunkName: "reservation" */ '@/views/reservations/EmailLog'),
      },
    ],
  },
  {
    path: '/neppan/reservations',
    name: 'neppan-reservations',
    meta: {
      roles: ['ADMIN'],
    },
    component: () => import(
      /* webpackChunkName: "neppan-reservation" */ '@/views/neppan-reservation/NeppanReservation'
    ),
  },
  {
    path: '/guest-email',
    name: 'guest-email',
    component: () => import(
      /* webpackChunkName: "guest-email" */ '@/views/guest-email/Index'
    ),
    meta: {
      roles: INTERNAL,
    },
    children: [
      {
        name: 'guest-email-templates',
        path: '/guest-email',
        component: () => import(
          /* webpackChunkName: "neppan-reservation" */ '@/views/guest-email/EmailTemplates'
        ),
      },
    ],
  },
  {
    path: '/reports/housekeeping-companies',
    name: 'reports-housekeeping-companies',
    meta: {
      roles: REPORTS,
    },
    component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/HousekeepingCompanies'),
  },
  {
    path: '/reports/lost-founds',
    name: 'reports-lost-founds',
    meta: {
      roles: REPORTS,
    },
    component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/LostFounds'),
  },
  {
    path: '/reports/out-of-order',
    name: 'reports-out-of-order',
    meta: {
      roles: REPORTS,
    },
    component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/OutOfOrder'),
  },
  {
    path: '/reports/stocks',
    name: 'reports-stocks',
    meta: {
      roles: REPORTS,
    },
    component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/Stocks'),
  },
  {
    path: '/reports/expenses',
    name: 'reports-expenses',
    meta: {
      roles: REPORTS,
    },
    component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/Expenses'),
  },
  {
    path: '/reports/reservation-counter',
    name: 'reports-reservation-counter',
    meta: {
      roles: REPORTS,
    },
    component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/ReservationCounter'),
  },
  {
    path: '/reports/download',
    name: 'reports-download',
    meta: {
      roles: REPORTS,
    },
    component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/Download'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: () => import(/* webpackChunkName: "404" */ '@/views/static/NotFound'),
  },
  {
    path: '/upsell-items',
    name: 'upsell-items',
    component: () => import(/* webpackChunkName: "upsell-items" */ '@/views/upsell-items/UpsellItems'),
  },
  {
    path: '/upsell-item-orders',
    name: 'upsell-item-orders',
    component: () => import(/* webpackChunkName: "upsell-item-orders" */ '@/views/upsell-item-orders/UpsellItemOrders'),
  },
  {
    path: '/upsell-item-order/:id',
    name: 'upsell-item-order',
    redirect: '/upsell-item-order/:id/order-info',
    component: () => import(/* webpackChunkName: "upsell-item-orders" */ '@/views/upsell-item-orders/UpsellItemOrder'),
    meta: {
      roles: [...INTERNAL, ROLES.HOUSEKEEPER],
    },
    children: [
      {
        name: 'upsell-item-order-order-info',
        path: 'order-info',
        component: () => import(/* webpackChunkName: "upsell-item-order-order-info" */ '@/views/upsell-item-orders/OrderInfo'),
      },
      {
        name: 'upsell-item-order-item-list',
        path: 'item-list',
        component: () => import(/* webpackChunkName: "upsell-item-order-item-list" */ '@/views/upsell-item-orders/ItemList'),
      },
    ],
  },
];

const _createRouter = () => createRouter({
  history: createWebHistory(),
  scrollBehavior(to, _from, savedPosition) {
    if (savedPosition) {
      return Promise.resolve(savedPosition);
    }
    const position = {};
    return new Promise((resolve) => {
      // check if any matched route config has meta that requires scrolling to top
      if (to.matched.some((m) => m.meta.scrollToPos)) {
        // coords will be used if no selector is provided,
        // or if the selector didn't match any element.
        if (to.meta.scrollToPos) {
          position.x = to.meta.scrollToPos.x;
          position.y = to.meta.scrollToPos.y;
        }
      }
      resolve(position);
    });
  },
  routes: constantRoutes,
});

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export const asyncRoutes = [];

const router = _createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = _createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;
