import { reserveFormat } from './util';
import { DATE_FORMAT, DATETIME_FORMAT, DATE_MONTH_FORMAT } from '@/config/dates';

export const getDateFormat = function () {
  return reserveFormat() ? DATE_FORMAT.JA : DATE_FORMAT.DEFAULT;
};

export const getDatetimeFormat = function () {
  return reserveFormat() ? DATETIME_FORMAT.JA : DATETIME_FORMAT.DEFAULT;
};

export const getDateMonthFormat = function () {
  return reserveFormat() ? DATE_MONTH_FORMAT.JA : DATE_MONTH_FORMAT.DEFAULT;
};
