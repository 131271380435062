<template>
  <div class="name-wrap">
    <div v-show="!record.readAt" class="circle-danger" />
    <div class="has-text-weight-bold">
      {{ getTitle(record) }}
    </div>
    <div>{{ getDescription(record) }}</div>
  </div>
</template>

<i18n src="../../alerts/locales.json"></i18n>

<script>
import getters from '@/views/alerts/mixins/getters';

export default {
  name: 'AlertTitle',
  mixins: [getters],
  props: {
    record: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.name-wrap {
  position: relative;
  padding-left: 25px;
  line-height: 1;

  .circle-danger {
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -5px;
  }
}
</style>
