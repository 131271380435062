import axios from 'axios';
import axiosRetry from 'axios-retry';
import Message from 'ant-design-vue/lib/message';
import i18n from '@/i18n';
import store from '@/store';
import { getToken, logout, getTokenExpiration } from '@/utils/auth';
import { AUTH_ERROR, REFRESH_TOKEN_ERROR } from '@/config/request-error-codes';
import { SKIP_API_URL } from '@/config/routers';

export const { CancelToken } = axios;
export const { isCancel } = axios;

export function Service(config) {
  // create an axios instance
  const service = axios.create(config);

  // request interceptor
  service.interceptors.request.use(
    async (config) => {
      const expiration = getTokenExpiration() || 0;

      if (!SKIP_API_URL.includes(config.url)) {
        if (expiration < new Date().getTime()) {
          await store
            .dispatch('user/refreshToken')
            .catch(() => logout());
        }
      }

      // do something before request is sent
      config.headers['X-API-KEY'] = process.env.VUE_APP_KONG_API_KEY;

      if (store.getters.token) {
        // let each request carry token
        config.headers.Authorization = `Bearer ${getToken()}`;
      }

      return config;
    },
    (error) => {
      // do something with request error
      console.log(error); // for debug
      return Promise.reject(error);
    },
  );

  // response interceptor
  service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
       * Determine the request status by custom code
       * Here is just an example
       * You can also judge the status by HTTP Status Code
       */
    (response) => {
      const { request } = response;
      const responseType = request.responseType || '';

      if (responseType === 'blob' || responseType === 'arraybuffer') {
        return response;
      }
      return response.data;
    },
    (err) => {
      if (isCancel(err)) {
        return Promise.reject(err);
      }

      let message = '';
      const { response } = err;

      if (response && response.data && response.data.error) {
        const { error } = response.data;
        const $t = i18n.global.t;
        const translatable = $t(`error.${error.type}`);
        message = translatable.startsWith('error.') ? $t(error.message) : translatable;

        if (AUTH_ERROR.includes(error.code)) {
          Message.error(message, 10);

          return logout();
        }

        if (REFRESH_TOKEN_ERROR.includes(error.code)) {
          return Promise.reject(error.code);
        }
      } else if (response && response.data && response.data.message) {
        message = response.data.message;
      } else {
        message = err.message;
      }

      Message.error(message, 5);

      return Promise.reject(err);
    },
  );
  // retry
  axiosRetry(service, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

  return service;
}

export default Service({
  baseURL: process.env.VUE_APP_API_URL, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 480000, // request timeout
});
