import {
  getConfiguration,
  saveConfiguration,
  list,
  create,
  update,
  deletes,
  shows,
  exports,
  imports,
  listTravelWebsites,
} from '@/api/rms/toplines';

const state = {};

const mutations = {};

const actions = {
  getConfiguration(_, params) {
    return getConfiguration(params);
  },
  saveConfiguration(_, payload) {
    return saveConfiguration(payload);
  },
  list(_, payload) {
    return list(payload);
  },
  create(_, payload) {
    return create(payload);
  },
  update(_, payload) {
    return update(payload);
  },
  deletes(_, payload) {
    return deletes(payload);
  },
  shows(_, payload) {
    return shows(payload);
  },
  exports(_, payload) {
    return exports(payload);
  },
  imports(_, payload) {
    return imports(payload);
  },
  listTravelWebsites() {
    return listTravelWebsites();
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
