import GoogleMapsApiLoader from 'google-maps-api-loader';
import Message from 'ant-design-vue/lib/message';
import { getLocale } from '@/utils/locale';

const state = {
  map: undefined,
  mapCenter: {
    lat: 36,
    lng: 138,
  },
};

const mutations = {
  SET_MAP: (state, payload) => {
    state.map = payload;
  },
  SET_MAP_CENTER: (state, payload) => {
    state.mapCenter = payload;
  },
};

const actions = {
  load({ commit }, lang) {
    return new Promise((resolve, reject) => {
      GoogleMapsApiLoader({
        libraries: ['places'],
        apiKey: process.env.VUE_APP_GOOGLE_MAP_KEY,
        language: lang || getLocale(),
      }).then(() => {
        commit('SET_MAP', window.google.maps);
        resolve();
      }).catch((e) => {
        Message.error(e.message);
        reject(e);
      });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
