import {
  housekeepingCompanies,
  housekeepingCompaniesExport,
  lostFounds,
  lostFoundsExport,
  outOfOrder,
  outOfOrderExport,
  stocks,
  stocksExport,
  expenses,
  expensesExport,
  expensesPaymentExport,
  expensesApprove,
  expensesReject,
  reservationCounterList,
  reservationCounterExport,
  reservationCounterHouseKeepingTotal,
  downloadReservationsReport,
} from '@/api/reports';

const state = {
  loading: false,
};

const mutations = {
  SET_LOADING: (state, payload) => {
    state.loading = payload;
  },
};

const actions = {
  housekeepingCompanies(_, payload) {
    return housekeepingCompanies(payload);
  },
  housekeepingCompaniesExport(_, payload) {
    return housekeepingCompaniesExport(payload);
  },
  lostFounds(_, payload) {
    return lostFounds(payload);
  },
  lostFoundsExport(_, payload) {
    return lostFoundsExport(payload);
  },
  outOfOrder(_, payload) {
    return outOfOrder(payload);
  },
  outOfOrderExport(_, payload) {
    return outOfOrderExport(payload);
  },
  stocks(_, payload) {
    return stocks(payload);
  },
  stocksExport(_, payload) {
    return stocksExport(payload);
  },
  expenses(_, payload) {
    return expenses(payload);
  },
  expensesExport(_, payload) {
    return expensesExport(payload);
  },
  expensesPaymentExport(_, payload) {
    return expensesPaymentExport(payload);
  },
  expensesApprove(_, payload) {
    return expensesApprove(payload);
  },
  expensesReject(_, payload) {
    return expensesReject(payload);
  },
  reservationCounter(_, payload) {
    return reservationCounterList(payload);
  },
  reservationCounterExport(_, payload) {
    return reservationCounterExport(payload);
  },
  reservationCounterHouseKeepingTotal(_, payload) {
    return reservationCounterHouseKeepingTotal(payload);
  },
  downloadReservationsReport(_, payload) {
    return downloadReservationsReport(payload);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
