import { HIDE_SIMULATION_PAGE } from '@/utils/settings';
import { getFullname } from '@/utils/users';

const getters = {
  token: (state) => state.user.token,
  isAuthenticated: (state) => !!state.user.uid,
  displayName: (state) => getFullname({ fname: state.user.firstName, lname: state.user.lastName }),
  userEmail: (state) => state.user.email,
  uid: (state) => state.user.uid,
  userPartner: (state) => state.user.partner,
  roles: (state) => state.user.roles,
  permissions: (state) => state.user.permissions,
  isAdmin: (state) => state.user.roles.indexOf('ADMIN') !== -1,
  hideSimulationPage: () => HIDE_SIMULATION_PAGE,
  properties: (state) => state.properties?.property?.list,
};
export default getters;
